import { useRouter, useRoute } from "vue-router";
import { useStore } from 'vuex'
export default function changeRouter(){
    const router = useRouter()
    const route = useRoute()    

    const store = useStore()

    const changePath = (path, query={}) => {
        router.push({path, query})
    }
    const backPath = () => {
        history.back()
    }
    const repacePath = (path,  query={}) => {
        router.replace({path, query})
    }
    const getQuery = () => {
        return route.query
    }

    const showPopup = () => {
        const userInfo = store.getters['common/userInfo']
        console.log(userInfo)
    }
    return {
        changePath,
        backPath,
        route,
        getQuery,
        repacePath,
        showPopup
    }
}