const common = {
    namespaced: true,
    state: {
        showTextEdit: false,
        showLayer: false,
        showTransper: false,
        showShadow: false,
        loading: true,
        showVip: false,
        vipState: true,
        userInfo: {
            is_trial: 0,
            is_vip: 0,
            vip_expiry: '',
            vip_id: 0
        }
    },
    getters: {
        showTextEdit: (state) => {
            return state.showTextEdit
        },
        showLayer: (state) => {
            return state.showLayer
        },
        showTransper: (state) => {
            return state.showTransper
        },
        showShadow: (state) => {
            return state.showShadow
        },
        loading: (state) => {
            return state.loading
        },
        showVip: (state) => {
            return state.showVip
        },
        vipState: (state) => {
            return state.vipState
        },
        userInfo: (state) => {
            return state.userInfo
        }
    },
    mutations: {
        SET_TEXT_SHOW (state, data) {
            state.showTextEdit = data
        },
        SET_LAYER_SHOW (state, data) {
            state.showLayer = data
        },
        SET_TRANSPER_SHOW (state, data) {
            state.showTransper = data
        },
        SET_SHADOW_SHOW (state, data) {
            state.showShadow = data
        },
        SET_LOADING (state, data) {
            state.loading = data
        },
        SET_VIP(state, data) {
            state.showVip = data
        },
        SET_VIP_STATE(state, data) {
            state.vipState = data
        },
        SET_USER_INFO(state, data){
            state.userInfo = data
        }
    },
    actions: {
        setLoading({commit}, data){
            commit('SET_LOADING', data)
        },
        setUserInfo({commit, dispatch}, data){
            console.log(data)
            commit('SET_USER_INFO', data)
            dispatch('setVipState')
        },
        setVipState({commit, state}){
            if(state.vipState){
                let {vip_id, is_vip, is_trial} = state.userInfo
                // console.log(is_vip, is_trial, vip_id)
                if(!is_vip && !is_trial && vip_id !=0){
                    commit('SET_VIP', true)
                }
                if(is_trial){
                    commit('SET_VIP', true)
                }
                if(vip_id == 1 && !is_trial){
                    commit('SET_VIP', true)
                }
                if(vip_id == 2 && !is_vip){
                    commit('SET_VIP', true)
                }
            }
        }
    }
}

export default common