<template>
    <Header title="个人信息" :space="false">
        <div class="space">基础信息</div>
        <van-cell-group>
            <van-cell to="/headerImg">
                <template #title>
                    <div class="dsp">
                        <span class="star">头像</span>
                    </div>
                </template>
                <template #value>
                    <div class="dsp right">
                        <div class="round"><van-image :src="state.user.avatar" /></div>
                        <van-icon name="arrow" class="icon"/>
                    </div>
                </template>
            </van-cell>
            <van-cell :value="state.user.nickname" is-link @click="edit('nickname')">
                <template #title>
                    <span class="star">姓名</span>
                </template>
            </van-cell>
            <van-cell :value="state.user.career?state.user.career:'请输入...'" is-link @click="edit('career')">
                <template #title>
                    <span class="star">职位/称号</span>
                </template>
            </van-cell>
        </van-cell-group>
        <div class="space">
            <span>联系方式</span>
            <span class="fs12">* 开始后，才会在名片中显示</span>
        </div>
        <van-cell-group>
            <van-cell :value="state.user.mobile" >
                <template #title>
                    <div class="dsp">
                        <span class="star">手机</span>
                    </div>
                </template>
                <template #value>
                    <div class="dsp cellRight">
                        <span @click="editphone">{{state.user.mobile ? state.user.mobile : '请输入...'}}</span>
                        <span><van-switch v-model="space.show_mobile" size="16px" @change="changeSwitch"/></span>
                    </div>
                </template>
            </van-cell>
            <van-cell title="微信号" >
                <template #value>
                    <div class="dsp cellRight">
                        <span  @click="edit('wechat')">{{state.user.wechat ? state.user.wechat : '请输入...'}}</span>
                        <span><van-switch v-model="space.show_wechat" size="16px" @change="changeWechat"/></span>
                    </div>
                </template>
            </van-cell>
        </van-cell-group>
        <!-- <van-button type="primary" block style="width: 90%; margin: 30px auto;" @click="clear">清除缓存</van-button> -->
    </Header>
    <van-popup v-model:show="state.show" position="bottom" :style="{ height: '100%', background: '#ededed' }" class="popup">
        <div class="Header">
            <span @click="state.show = false">取消</span>
            <span class="title">设置{{state.popupTitle}}</span>
            <span><van-button type="primary" size="mini" @click="confirm"><span class="btn-text">完成</span></van-button></span>
        </div>
        <div class="mt_20">
            <van-field v-model="state.popupVal" ref="input" autofocus :placeholder="`请输入${state.popupTitle}`" clearable/>
        </div>
    </van-popup>
    <van-popup v-model:show="phoneSet.show" position="bottom" :style="{ height: '100%', background: '#ededed' }" class="popup">
        <div class="Header">
            <span @click="closePhoneSet">取消</span>
            <span class="title">{{phoneSet.mobile? '更换': '完善'}}手机号</span>
            <span></span>
        </div>
        <div class="text">
            <!-- 完善手机号，立即获得3天试用会员 -->
            完善手机号
        </div>
        <van-cell-group inset  class="mb_30 mt_20">
            <van-field
                v-model="phoneSet.mobile"
                center
                clearable
                :placeholder="`请输入${phoneSet.mobile? '新手机号码': '手机号码'}`"
            >
            </van-field>
        </van-cell-group>
        <van-cell-group inset   class="mb_30">
            <van-field
                v-model="phoneSet.captcha"
                center
                clearable
                placeholder="请输入短信验证码"
            >
                <template #button>
                    <div class="spaceBtn" style="color: #5E89F7;">
                        <van-count-down
                            ref="countDown"
                            :time="seconds*1000"
                            :auto-start="false"
                            format="ss"
                            @change="onChange"
                            v-show="number > 0 && number < seconds"
                            style="color: #5E89F7;"
                        />
                        <span @click="sendCode">
                       {{ number === seconds || number === 0 ? '发送验证码' : 's后再次发送' }}</span>
                    </div>
                </template>
            </van-field>
        </van-cell-group>
        <div class="pd_30">
            <van-button type="primary" :disabled="!phoneSet.mobile || !phoneSet.captcha" block @click="changePhone">
                <span>确认{{state.user.mobile? '更换': '提交'}}</span>
            </van-button>
        </div>
    </van-popup>
</template>
<script setup name="userInfoSet">
    import { user, common, home } from '@/api/https'
    import { Toast } from 'vant'
    import { onMounted, reactive, ref, toRefs } from 'vue';

    const seconds = 60

    const state = reactive({
        show: false,
        popupVal: '',
        popupTitle: '',
        type: '',
        user:{
            avatar: '',
            nickname: '',
            career: '',
            mobile: '',
            wechat: '',
        },
    })
    const countDown = ref(null)
    onMounted(() => {
        getInfo()
        getSpace()
    })

    const getSpace = () => {
        home.spaceGet().then(({data}) => {
            space.show_mobile = data.show_mobile? true:false
            space.show_wechat = data.show_wechat? true:false
        })
    }

    const getInfo = () => {
        user.get_info().then(res => {
            state.user = res.data
        })
    }
    const input = ref()
    const edit = (type) => {
        state.type = type
        state.popupVal = state.user[type]
        if(type === 'nickname'){
            state.popupTitle = '昵称'
        }else if(type === 'career'){
            state.popupTitle = '职位/称号'
        }else{
            state.popupTitle = '微信号'
        }
        state.show = true
    }
    const confirm = () => {
        if(!state.popupVal) return
        state.show = false
        let { type } = state
        state.user[type] = state.popupVal
        let {avatar, nickname, career, wechat} = state.user
        user.update_info({avatar, nickname, career, wechat})
    }
    //更改状态
    const space = reactive({
        show_mobile: false,
        show_wechat: false
    })
    const changeSwitch = (val) => {
        space.show_mobile = val
        home.spaceSet({...space})
    }
    const changeWechat = (val) => {
        space.show_wechat = val
        home.spaceSet({...space})
    }

    //更换手机号

    const phoneSet = reactive({
        show: false,
        mobile: '',
        captcha: '',
        number: seconds,
    })
    const editphone = () => {
        phoneSet.show = true
        phoneSet.phoneVal = state.user.mobile
    }
    const closePhoneSet = () => {
        phoneSet.show = false
        phoneSet.number = 60
        countDown.value.reset()
    }
    const changePhone = () => {
        let {mobile, captcha} = phoneSet
        if(!mobile || !captcha) return
        user.change_mobile({mobile, captcha}).then(() => {
            // if(!state.user.mobile){
            //     user.vip_trial()
            // }
            state.user.mobile = mobile
            phoneSet.show = false
        })
    }

    const sendCode = () => {
        if(!phoneSet.mobile){
            Toast.fail('请先输入号码')
            return
        }
        if(phoneSet.number > 0 && phoneSet.number < seconds) return
        countDown.value.reset()
        countDown.value.start()
        common.sms({
            type: 'change_mobile',
            mobile: phoneSet.mobile
        }).then(({msg}) =>{
            Toast.success(msg)
        })
        
    }

    const onChange = ({seconds}) => {
        phoneSet.number = seconds
    }

    // const clear = () => {
    //     common.restLogin().then(res => {
    //         console.log(res)
    //     })
    // }

    const {number} = toRefs(phoneSet)
</script>
<style scoped lang="less">
.round{
    width: 80px;
    height: 80px;
    background-color: #ccc;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    img{
        width: 100%;
    }
}
.dsp{
    height: 100%;
    display: flex;
    align-items: center;
    &.cellRight{
        justify-content: flex-end;
        >span:first-child{
            flex: 1;
            margin-right: 10px;
        }
    }
}
.spaceBtn{
    display: flex;
    align-items: center;
}
.right{
    justify-content: flex-end;
    color: #969799;
    
}
.right .icon{
    margin-left: var(--van-padding-base);
    font-size: var(--van-cell-icon-size);
}
.star{
    position: relative;
}
.star::after{
    position: absolute;
    right: -8Px;
    color: var(--van-cell-required-color);
    font-size: var(--van-cell-font-size);
    content: "*";
}
.pd30{
    padding: 30px;
}
.popup{
    .text{
        padding: 30px;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
    }
}
</style>