import { inject } from "vue";
export default function useSelect(){
    
    const canvasEditor = inject('canvasEditor', () => {});
    const fabric = inject('fabric');

    // const columns = [
    //     { text: '宋体', value: 'SimSun' },
    //     { text: '黑体', value: 'SimHei' },

    //     { text: '微软雅黑', value: 'Microsoft Yahei' },
    //     { text: '微软正黑体', value: 'Microsoft JhengHei' },
    //     { text: '楷体', value: 'KaiTi' },

    //     { text: '新宋体', value: 'NSimSun' },
    //     { text: '仿宋', value: 'FangSong' },
    //     { text: 'Arial', value: 'Arial' },

    // ]
    const columns = [
        { text: '微软雅黑', value: 'Microsoft Yahei' },
        { text: '微软正黑体', value: 'Microsoft JhengHei' },
        // { text: '楷体s', value: 'KaiTi' },
        { text: 'Arial', value: 'Arial' },
        { text: '方正仿宋', value: 'fangzhengfangsong' },
        { text: '方正书宋', value: 'fangzhengshusong' },
        { text: '方正黑体', value: 'fangzhengheiti' },
        { text: '方正楷体', value: 'fangzhengkaiti' },
    ]
    return{
        canvasEditor,
        columns,
        fabric,
    }
}