<template>
    <van-popup
        v-model:show="show"
        position="bottom"
        :overlay="false"
        class="van-safe-area-bottom"
    >
        <div class="wrap">
            <div class="option">
                <!-- <span @click="editText">取消</span> -->
                <span @click="editText">编辑文字</span>
                <span class="save" @click="save">完成</span>
            </div>
            <div class="btns">
                <div @click="editText">修改文字</div>
                <div @click="showFamily">字体</div>
                <div @click="changeText('fontStyle', 'italic')">倾斜</div>
                <div @click="showShadow">阴影</div>
                <div @click="changeText('fontWeight', 'bold')">加粗</div>
                <div @click="pickShow">颜色</div>
                <div @click="changeText('underline',  '')">下划线</div>
                <div @click="changeText('linethrough', '')">删除线</div>
            </div>
            <!-- <div class="tools">
                <div class="colorPick" @click="pickShow"></div>
                <div class="fontWrap" @click="showFamily">{{ fontVal }}</div>
            </div>
            <div class="icons">
                <van-icon class-prefix="iconfont" name="jiacu" @click="changeText('fontWeight', 'bold')"/>
                <van-icon class-prefix="iconfont" name="xieti" @click="changeText('fontStyle', 'italic')"/>
                <van-icon class-prefix="iconfont" name="xiahuaxian" @click="changeText('underline', '')" />
                <van-icon class-prefix="iconfont" name="zhonghuaxian" @click="changeText('linethrough', '')"/>
                <div @click="showTransper" v-if="false">
                    <img class="trans" src="../../../../public/image/9.png" alt="">
                </div>
                <div @click="showShadow">
                    <img class="yy" src="../../../../public/image/10.png" alt="">
                </div>
            </div> -->
        </div>
    </van-popup>
    <ColorPick ref="colorPick" @change="(val)=>{changeText('fill', val)}"></ColorPick>
    <add-text-popup ref="addText" @confirm="confirmText"></add-text-popup>
    <TransperPopup ref="transper"></TransperPopup>
    <fontFamilyPick ref="fontFamily" @update="updateFont"></fontFamilyPick>
    <shadowSet ref="shadowPopup"></shadowSet>
</template>
<script name="textEdit" setup>
    import { computed, ref, watch } from 'vue';
    import { useStore } from 'vuex';
    import addTextPopup from '@/views/placard/modules/addTextPopup.vue';
    import ColorPick from './colorPicker.vue'
    import TransperPopup from './transperPopup.vue';
    import fontFamilyPick from './fontFamilyPick.vue'
    import shadowSet from './shadowSet.vue'
    import useSelect from './useCanvas'
    const { canvasEditor, columns } = useSelect()

    const store = useStore()
    const show = computed(() => {
        return store.getters['common/showTextEdit']
    })

    const colorPick = ref()
    const pickShow = () =>{
        colorPick.value.open()
    }
    const save = () => {
        store.commit('common/SET_TEXT_SHOW', false)
    }

    const changeText = (key, value) => {
        const activeObject = canvasEditor().getActiveObjects()[0];
        if(key === 'linethrough'){
            const nValue = activeObject[key] === false
            activeObject && activeObject.set(key, nValue);
        }
        else if(key === 'fill'){
            activeObject && activeObject.set('fill', value);
        }
        else{
            const nValue = activeObject[key] === 'normal' ? value : 'normal';
            activeObject && activeObject.set(key, nValue);
        }
        canvasEditor().renderAll();
    };

    //改字
    const addText = ref()
    const editText = () => {
        const activeObject = canvasEditor().getActiveObjects()[0];
        addText.value.open(activeObject.text)
    }
    const confirmText = (val) =>{
        const activeObject = canvasEditor().getActiveObjects()[0];
        activeObject && activeObject.set('text', val);
        canvasEditor().renderAll();
    }

    //修改透明度
    // const transper = ref()
    // const showTransper = () =>{
    //     store.commit('common/SET_TEXT_SHOW', false)
    //     const activeObject = canvasEditor().getActiveObjects()[0];
    //     let opacity = activeObject.opacity
    //     transper.value.open((10000-opacity*10000)/100)
    // }
    //修改字体
    const fontVal = ref()
    watch(show, (val) => {
        if(val){
            const activeObject = canvasEditor().getActiveObjects()[0];
            let val = activeObject.fontFamily
            fontVal.value = columns.filter(el => el.value == val)[0].text
        }
    })
    const fontFamily = ref()
    const showFamily = () => {
        fontFamily.value.open()
    }
    const updateFont = (val) => {
        fontVal.value = columns.filter(el => el.value == val)[0].text
    }

    //修改阴影
    const shadowPopup = ref()
    const showShadow = () => {
        shadowPopup.value.open()
    }

</script>
<style lang="less" scoped>
.wrap{
    padding: 20px 30px 30px;
    font-size: 28px;
    .option{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 26px;
        .save{
            color: orangered;
            letter-spacing: 2px;
        }
    }
    .btns{
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        >div{
            width: 23%;
            border: 1Px solid #ccc;
            text-align: center;
            padding: 10px;
            border-radius: 10px;
            margin: 10px 0;
        }
    }
    .tools{
        margin-top: 30px;
        display: flex;
        align-items: center;
        .fontWrap{
            width: 300px;
            height: 60px;
            line-height: 60px;
            border: 1Px solid #eee;
            padding: 0 30px;
            border-radius: 60px;
            font-size: 28px;
            margin-left: 30px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .icons{
        font-size: 40px;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        .trans{
            width: 38px;
            height: 38px;
        }
        .yy{
            width: 42px;
            height: 42px;
        }
    }
    .colorPick{
        width: 35px;
        height: 35px;
        background-color: #333;
        border-radius: 4px;
    }

}

</style>