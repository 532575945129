<template>
    <Header title="推荐奖励" :space="false">
        <div class="infoDetail" :class="{'active': userInfo.is_vip}">
            <div class="topHeader" @click="changePath('/rule')">
                <span>每推荐一名会员奖励33%</span>
                <span>
                    详见奖励规则<van-icon name="arrow" />
                </span>
            </div>
            <van-row class="nums fs28">
                <van-col span="12">
                    <div>累计奖励</div>
                    <div class="fs35 mt_20">{{total.award}}</div>
                </van-col>
                <van-col span="12"> 
                    <div>可提现</div>
                    <div class="fs35 mt_20">{{total.balance}}</div>
                </van-col>
            </van-row>
            <div class="noticeSpace"></div>
        </div>
        <div class="btns">
            <van-button round type="default" block text="推荐使用" to="/recommend" class="boxShadow" />
            <van-button round block type="default" to="/cash" text="去提现"  class="boxShadow" />
        </div>
        <div class="space"></div>
        <van-list
            v-model:loading="loading"
            :finished="finished"
            :finished-text="list.length ? '没有更多了' : '暂无数据'"
        >
            <van-cell v-for="list in list" :key="list.id" class="lable_no_top">
                <template #title>
                    <div class="dsp">
                        <div class="round"><van-image :src="list.consumer.avatar" alt="" /></div>
                        <div>
                            <div class="name">{{list.consumer.nickname}}({{ list.sub_type === 'new' ? '新用户' : '老用户' }})</div>
                            <div class="fs26">{{list.award_time}}</div>
                        </div>
                    </div>
                </template>
                <template #value>
                    <span class="money">+{{ list.money }}</span>
                </template>
            </van-cell>
        </van-list>
    </Header>
</template>
<script name="reward" setup>
    import { reward } from '@/api/https'
    import { onMounted, ref, reactive, toRefs } from 'vue'
    import changeRouter from '@/hooks/routerChange'
    const { changePath } = changeRouter()

    onMounted(() => {
        getTotal()
        getList(1)
    })

    const total = ref({
        award: 0,
        friends: 0,
        posters: 0
    })
    const getTotal = () => {
        reward.withdraw().then(({data}) =>{
            total.value = data.amount
        })
    }
    const userInfo = ref({
        is_vip: 0
    })

    const state = reactive({
        loading: false,
        finished: true,
        list: []
    })

    const getList = (page) => {
        reward.record({page}).then(res =>{
            let {has_more, current_page, data} = res.data
            if(has_more){
                getList(current_page+1)
            }
            state.list.push(...data)
        })
    }

    const {
        loading,
        finished,
        list
    } = toRefs(state)

</script>
<style lang="less" scoped>
.infoDetail {
    background: radial-gradient(circle, #5E89F7 0%, #4571E3 100%);
    padding-top: 30px;
    position: relative;
    color: #fff;
    .topHeader{
        padding: 20px 30px;
        display: flex;
        font-size: 28px;
        justify-content: space-between;
    }
    .infoBox{
        padding:0 30px;
        display: flex;
        align-items: top;
        position: relative;
        .info{
            flex: 1;
            display: flex;
            padding-top: 20px;
            align-items: center;
            .headImg{
                width: 105px;
                height: 105px;
                border-radius: 100%;
                background: #ccc;
                margin-right: 26px;
                overflow: hidden;
                img{
                    width: 100%;
                }
            }
        }
    }
    .nums{
        margin-top: 50px;
        text-align: center;
        .fs35{
            margin-bottom: 5px;
        }
    }
    .noticeSpace{
        margin-top: 30px;
        height: 30px;
    }
    .notice{
        margin: 30px 22px 0;
        height: 73px;
        background: linear-gradient(-50deg, #FFCD95 0%, #FFE9D0 100%);
        border-radius: 26px 26px 0px 0px;
        position: relative;
        overflow: hidden;
        display: flex;
        padding-left: 22px;
        align-items: center;
        color: #8D5B24;
        letter-spacing: 1px;
        &::before{
            content: '';
            left: 332px;
            position: absolute;
            top: -20px;
            width: 60px;
            height: 60px;
            background: linear-gradient(-45deg, #FFE9D0 0%, rgba(255,211,160,0) 100%);
            border-radius: 50%;
        }
        &::after{
            content: '';
            position: absolute;
            width: 60px;
            height: 60px;
            background: linear-gradient(-45deg, #FFE9D0 0%, rgba(255,211,160,0) 100%);
            border-radius: 50%;
            right: 12px;
            bottom: -12px;
        }
    }
}
.btns{
    margin-top: -30px;
    padding: 0 42px 30px;
    display: flex;
    gap: 26px;
    .boxShadow{
        box-shadow: 0 0 10px rgba(0,0,0,.2);
    }
}
.round{
    width: 80px;
    height: 80px;
    background-color: #eee;
    border-radius: 100%;
    margin-right: 30px;
    overflow: hidden;
    img{
        width: 100%;
    }
}
.name{
    font-weight: 600;
}
.money{
    color: #e21616;
    font-weight: 600;
    font-size: 35px;
    font-family: sans-serif;
}
</style>        