<template>
    <van-popup 
        v-model:show="show" 
        position="bottom"
        :overlay="false"
        class="van-safe-area-bottom"
    >
        <div class="wrap">
            <div class="pupBtn" v-if="false">
                <span @click="confirmText(0)">取消</span>
                <span @click="confirmText(1)">完成</span>
            </div>
            <div class="popupBody">
                <span>透明度</span>
                <div class="slide">
                    <van-slider v-model="state.value" @update:model-value="onChange" />
                </div>
                <span>{{state.value}}%</span>
            </div>
        </div>
    </van-popup>
</template>
<script setup name="transperPopup">
import { reactive, defineExpose, computed, watch } from 'vue';
import useSelect from './useCanvas'
const { canvasEditor } = useSelect()

import { useStore } from 'vuex';
const store = useStore()

const show = computed(() => {
    return store.getters['common/showTransper']
}) 

const state = reactive({
    value: 0,
    default: 0
})
watch(show, (val) =>{
    if(val){
        const activeObject = canvasEditor().getActiveObjects()[0];
        let opacity = activeObject.opacity
        state.value = Math.round((10000-opacity*10000)/100)
        state.default = Math.round((10000-opacity*10000)/100)
    }
})
const open = (data) => {
    store.commit('common/SET_TRANSPER_SHOW', true)
    state.value = data ? Math.round(data) : 0
    state.default = data ? Math.round(data) : 0
}

const onChange = (val) => {
   state.value = val
   const activeObject = canvasEditor().getActiveObjects()[0];
   activeObject && activeObject.set('opacity', (100-val)/100)
   canvasEditor().renderAll()
}

const confirmText = (type) => {
    const activeObject = canvasEditor().getActiveObjects()[0];
    if(!type){
        activeObject && activeObject.set('opacity', (100-state.default)/100)
        canvasEditor().renderAll()
    }
    if(activeObject.type == 'i-text'){
        store.commit('common/SET_TEXT_SHOW', true)
        store.commit('common/SET_TRANSPER_SHOW', false)
    }
    if(activeObject.type == 'image'){
        store.commit('common/SET_TRANSPER_SHOW', false)
    }
}

defineExpose({
    open
})
</script>
<style lang="less" scoped>
.wrap{
    padding: 30px;
    .popupBody{
        display: flex;
        // justify-content: space-around;
        font-size: 30px;
        align-items: center;
        margin-top: 30px;
        gap: 40px;
        >span{
            white-space: nowrap;
        }
        .slide{
            flex: 1;
        }
    }
    .pupBtn{
        padding-bottom: 40px;
        font-size: 28px;
       display: flex;
       justify-content: space-between;
        color: #de8950;
        >span:first-child{
            margin-right: 30px;
            color: #1F2136;
        }
    }
}
</style>