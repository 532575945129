import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
const app = createApp(App)
import { vant } from '@/vant.config.js'
vant(app)

import './assets/css/IconFont/iconfont.css'
import 'vue-cropper/dist/index.css';
import './assets/css/common.less'
import 'lib-flexible'

import store from './store'

// import VConsole from 'vconsole'
// const vConsole = new VConsole()

import Header from '@/components/header'
app.component('Header', Header)

import Loading from '@/components/loading'
app.component('Loading', Loading)

import VueCropper from 'vue-cropper'; // 导入vue-cropper模块

app.use(VueCropper)
// app.use(vConsole)
app.use(router)
app.use(store)
app.mount('#app')
