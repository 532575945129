<template>
    <van-tabbar v-model="state.active" :placeholder="true" :before-change="onChange">
        <van-tabbar-item v-for="(bar, index) in state.tabbar" :key="index">
            <span>{{bar.title}}</span>
            <template #icon="props">
                <img class="img" v-if="index<4" :src="props.active ? require(`../../public/image/tab_${index+1}.png`) : require(`../../public/image/tab_${index+1}_1.png`)" />
            </template>
        </van-tabbar-item>
    </van-tabbar>
</template>
<script name="foot" setup>
import { onMounted, reactive } from 'vue';
import changeRouter from '@/hooks/routerChange'
const { route, getQuery, changePath } = changeRouter()
import { Dialog } from 'vant';
// import {home} from '@/api/https'

import {useStore} from 'vuex'
const store = useStore()

const state = reactive({
    active: 0,
    tabbar: [
        {
            title: '首页',
            path: '/monitor/home',
        },
        {
            title: '助手',
            path: '/monitor/helper',
        },
        {
            title: '海报',
            path: '/monitor/placard',
        },
        {
            title: '我的',
            path: '/monitor/user',
        },
    ]
})
onMounted(() => {
    state.active = state.tabbar.findIndex(el => el.path === route.path)
})

const onChange = (val) => {
    let { uid } = getQuery()
    let {is_trial, is_vip, id} = store.getters['common/userInfo']
    if(uid && uid != id){
        Dialog.confirm({
            title: '提示',
            message:
                '您也要AI助手吗？',
            })
        .then(() => {
            localStorage.setItem('inviteUid', state.uid)
            changePath('/', {uid: state.uid})
        })
        .catch(() => {
            // on cancel
        });
        return
    }else{
        if(val>0 && val <3){
            if(!is_trial && !is_vip){
                store.commit('common/SET_VIP_STATE', true)
                store.dispatch('common/setVipState')
                return false
            }else{
                state.active = val
                changePath(state.tabbar[val].path)
            }
        }else{
            state.active = val
            changePath(state.tabbar[val].path)
        }
    }
}

// const routeTo = (val) => {
//     let { uid } = getQuery()
//     if(uid){
//         home.info().then(res => {
//             if(res.data.user.id != uid  && route.path === 'mointor/home'){
//                 state.active = 0
//                return
//             }else{
//                 changePath(val)
//             }
//         })
//     }else{
//         changePath(val)
//     }
// }

</script>
<style scoped>
    .img{
        height: 25Px
    }
</style>