<template>
    <van-popup
        v-model:show="state.show"
        position="bottom"
        :overlay-style="{background: 'none'}"
        class="van-safe-area-bottom"
    >
    <van-picker
        v-if="state.pickshow"
        :default-index="state.fontVal"
        :columns="columns"
        @change = "change"
        @cancel="(val) => onConfirm(0, val)"
        @confirm="(val) => onConfirm(1, val)"
    />
    </van-popup>
    <van-overlay :show="loading" class="cover opacity" z-index="100">
        <van-loading color="#5E89F7"></van-loading>
    </van-overlay> 
</template>
<script setup name="fontFamily">
    import { defineExpose, reactive, defineEmits, ref } from 'vue'
    import FontFaceObserver from 'fontfaceobserver'
    import useSelect from './useCanvas';
    const { columns, canvasEditor } = useSelect()

    const state = reactive({
        show: false,
        pickshow: false,
        fontVal: 0,
        defaultVal: ''
    })

    const loading = ref(false)

    const open = () => {
        const activeObject = canvasEditor().getActiveObjects()[0];
        let val = activeObject.fontFamily
        state.fontVal = columns.findIndex(el => el.value === val)
        state.defaultVal = val
        state.show = true
        state.pickshow = true
    }
    const change = ({value})=>{
        const activeObject = canvasEditor().getActiveObjects()[0];
          // 跳过加载的属性;
        const skipFonts = ['Arial', 'Microsoft Yahei', 'Microsoft JhengHei'];
        if (skipFonts.includes(value)) {
            activeObject && activeObject.set('fontFamily', value)
            canvasEditor().renderAll()
            return;
        }
        loading.value = true
        const font = new FontFaceObserver(value);
        font.load(null,0)
        .then(() => {
            activeObject && activeObject.set('fontFamily', value)
            canvasEditor().renderAll()
          loading.value = false
        }).catch(() =>{
            loading.value = false
        })
    }

    const emits = defineEmits(['update'])

    const onConfirm = (type, {value}) => {
        state.show = false
        if(!type){
            const activeObject = canvasEditor().getActiveObjects()[0];
            activeObject && activeObject.set('fontFamily', state.defaultVal)
            canvasEditor().renderAll()
        }else{
            emits('update', value)
        }
        setTimeout(() => {
            state.pickshow = false
        }, 300 )
    }
    defineExpose({ open });
</script>
<style lang="less" scoped>
</style>