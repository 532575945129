<template>
    <Header title="AI助手" :space="false">
        <div class="topHeader">
            <span class="icon"></span>
            <span></span>
            <span class="icon"></span>
        </div>
        <div class="content">
            <div class="title">
                <div class="h1">{{detailInfo.name}}</div>
                <div>{{detailInfo.intro}}</div>
            </div>
            <van-cell-group inset>
                <van-field
                    v-model="message"
                    rows="5"
                    autosize
                    type="textarea"
                    placeholder="请输入信息"
                />
            </van-cell-group>
            <div class="pd_35 checkBox fs28" v-if="false">
                <van-checkbox v-model="checked" shape="square" @change="changeCheck">生成3个结果（会员专享）</van-checkbox>
            </div>
            <div class="pd_35 mt_30">
                <van-button type="primary" :disabled="!message.length || receiving" block @click="sendMsg"><span class="btn">点击生成</span></van-button>
            </div>
            <van-cell-group inset class="mt_30" v-if="showBorder">
                <div class="btnGroups">
                    <div>
                        <div class="stepper" v-if="checked && receiveMsg.length === 3">
                            <div class="stepper_minus" @click="minus"><van-icon name="arrow-left" /></div>
                            <div class="stepperNum">{{ sendNum+1 }}</div>
                            <div class="stepper_plus" @click="plus"><van-icon name="arrow" /></div>
                        </div>
                    </div>
                    <span class="btn copyBtn" @click="copy">复制</span>
                </div>
                <div class="receiveMsg" v-html="html"></div>
            </van-cell-group>
            <div class="sl">
                <span class="fs32">示例</span>
            </div>
            <div class="pd_35 ckWrap" v-for="(item, index) in detailInfo.example" :key="index">
                <div class="ckList">
                    <div class="ckDetail">{{item}}</div>
                    <div class="line"></div>
                    <div class="ckBtn" @click="fillingIn(item)">一键填入</div>
                </div>
            </div>
            <div class="sl">
                <span class="fs32">历史记录</span>
                <!-- <span class="fs28 ls"><van-icon class-prefix="iconfont" name="clock" class="clock fs30"/>历史记录</span> -->
            </div>
            <div class="pd_35 ckWrap" v-for="item in list" :key="item.id">
                <div class="ckList">
                    <div class="ckDetail">{{item.question}}</div>
                    <div class="line"></div>
                    <div class="ckBtn" @click="fillingIn(item.question)">一键填入</div>
                </div>
            </div>
            <van-empty description="暂无数据" v-if="!list.length"/>
        </div>
    </Header>
</template>
<script name="helper" setup>
import { reactive, toRefs, onMounted, computed } from "vue";
import { aiHelper, user, apiHttp } from '@/api/https'
import Header from '@/components/header.vue'
import ClipboardJS from "@/utils/clipboard.min.js" 
import { Toast } from 'vant';

import changeRouter from '@/hooks/routerChange'
const { getQuery } = changeRouter()

import { marked } from '@/assets/js/marked.js'

const state = reactive({
    showBorder: false,
    message: '',
    checked: false,
    sendNum: 0,
    source: null,
    receiveMsg: [''],
    list: [],
    userInfo: {},
    detailInfo: {},
    receiving: false
})


const { message, checked, list, receiveMsg, sendNum, showBorder, detailInfo, receiving } = toRefs(state)

onMounted(() => {
    getHistory()
    getUserInfo()
    helperDetail()
})

const helperDetail = () => {
    let { id } = getQuery()
    aiHelper.detail({id}).then(({data}) => {
        state.detailInfo = data
    })
}

const changeCheck = (val) =>{
    if(val && !state.userInfo.is_vip){
        Toast.fail('您还不是会员')
        state.checked = !val
    }
}

const receiveMessage = (uid, index) => {
    let { token } = JSON.parse(window.localStorage.getItem('UserInfo'))
    let { id } = getQuery()
    let path = `${apiHttp}/api/chat/receive?ai=${id}&uniqid=${uid}&token=` + encodeURIComponent(token);
    state.source = new EventSource(path)
    state.source.onopen = function (event) { // 与服务器连接成功回调
        console.log('成功与服务器连接', event)
    }
    state.source.onmessage = (event) => { // 监听未命名事件
        const data = JSON.parse(event.data);
        if(data.data){
            state.receiveMsg[index] += data.data.content
        }else{
            state.receiving = false
            Toast.fail(data.msg)
        }
    }
    state.source.onerror = function (error) { // 监听错误
        console.log('错误', error)
        state.receiving = false
        stopEventSource()

    }
}

const stopEventSource = () => {
    if (state.source) {
        state.source.close();
        state.source.removeEventListener('error', ()=>{state.source.close()});
        state.source = null;
    }
}

const getUserInfo = () =>{
    user.get_info().then(res => {
        state.userInfo = res.data
    })
}

const sendMsg = async() => {
    if(!state.message) return
    // if(!state.userInfo.is_vip){
    //     Toast.fail('您还不是会员')
    //     state.checked = false
    // }
    state.showBorder = true
    state.receiveMsg = state.checked ? ['','','']: ['']
    state.sendNum = 0
    state.receiving = true
    chart_send(state.sendNum)
}

const chart_send = (index) => {
    let { id } = getQuery()
    let request = {
        bot_id: id,
        message: state.message
    }
    aiHelper.chart_send(request).then(({data}) => {
        receiveMessage(data.uniqid, index)
    })
}

const getHistory = () => {
    let { id } = getQuery()
    let request = {
        bot_id: id,
        page : 1,
        pageSize: 10
    }
    aiHelper.chart_history(request).then(({data}) => {
        state.list.push(...data.data)
    })
}

const fillingIn = (val) => {
    state.message = val
    Toast({
        type: 'success',
        message: '填入成功',
        duration: 500
    })
}

const copy = () => {
    let {receiveMsg, sendNum} = state
    if(!receiveMsg[sendNum]){
        Toast.fail('无内容')
        return
    }
    let clipboard = new ClipboardJS('.copyBtn', {
        text: () => receiveMsg[sendNum]
    })
    clipboard.on('success', () => {
        Toast.success('复制成功')
        clipboard.off('success')
        clipboard.off('error')
        clipboard.destroy()
    })
    clipboard.on('error', () => {
        Toast.fail('复制失败')
        clipboard.off('success')
        clipboard.off('error')
        clipboard.destroy()
    })
}

const minus = () => {
    if(state.sendNum > 0 ){
        state.sendNum = state.sendNum-1
        if(!state.receiveMsg[state.sendNum] ){
            chart_send(state.sendNum)
        }
    } 
}
const plus = () => {
   if(state.sendNum < 2){
       state.sendNum = state.sendNum+1
        if(!state.receiveMsg[state.sendNum] ){
            chart_send(state.sendNum)
        }
   }
}

const html = computed(() => {
    return marked.parse(state.receiveMsg[state.sendNum])
    // return state.receiveMsg[state.sendNum]
})


</script>
<style lang="less" scoped>
.btnGroups{
    display: flex;
    padding: 20px 30px;
    justify-content: space-between;
}
.content{
    background-color: rgba(237, 238, 240, 0.5);
    position: relative;
    min-height: calc(100vh - 50Px - 91px);
    .title{
        font-size: 28px;
        color: #696974;
        padding: 50px 35px 35px;
        .h1{
            color: #1F2136;
            font-size: 66px;
            margin-bottom: 15px;
        }
    }
    .checkBox{
        margin: 28px 0 40px;
    } 
    .btn{
        letter-spacing: 2px;
        font-size: 30px;
    }
    .sl{
        padding: 35px 35px 0;
        display: flex;
        justify-content: space-between;
        color: #1F2136;
        align-items: center;
        .fs32{
            font-weight: 600;
        }
        .ls{
            color: #303335;
        }
        .clock{
            margin-right: 10px;
            color: #5B87F7;
        }
    }
    .ckWrap{
        padding-bottom: 30px;
    }
    .ckList{
        background: #fff;
        border-radius: 14px;
        font-size: 30px;
        margin-top: 30px;
        .ckDetail{
            padding: 30px;
            color: #303335;
            line-height: 1.5;
        }
        .line{
            border: 1Px dashed #E3E6E6;
        }
        .ckBtn{
            padding: 20px;
            text-align: center;
            color: #A9AFAF;
        }
    }
}
.topHeader{
    position: relative;
    color: #1F2136;
    padding: 0;
    >*{
        position: relative;
    }
    &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 500px;
        top: 0;
        left: 0;
        background: url(../../../public/image/helper-bg.png) no-repeat;
        background-size: 100%;
    }
}
.receiveMsg{
    padding: 30px;
    font-size: 26px;
    min-height: 200px;
}

.stepper{
    display: inline-block;
    -webkit-user-select: none;
    user-select: none;
    .stepper_minus,.stepper_plus{
        display: inline-block;
        position: relative;
        box-sizing: border-box;
        width: var(--van-stepper-input-height);
        height: var(--van-stepper-input-height);
        margin: 0;
        padding: 0;
        color: var(--van-stepper-button-icon-color);
        vertical-align: middle;
        background: var(--van-stepper-background-color);
        border: 0;
        line-height: var(--van-stepper-input-height);
        font-size: var(--van-stepper-input-font-size);
        text-align: center;
    }
    .stepperNum{
        display: inline-block;
        box-sizing: border-box;
        width: var(--van-stepper-input-width);
        height: var(--van-stepper-input-height);
        margin: 0 2Px;
        line-height: var(--van-stepper-input-height);
        padding: 0;
        color: var(--van-stepper-input-text-color);
        font-size: var(--van-stepper-input-font-size);
        text-align: center;
        vertical-align: middle;
        background: var(--van-stepper-background-color);
        border: 0;
        border-width: 1px 0;
        border-radius: 0;
    }
}
</style>