import { controls } from '@/views/placard/controlsPlugin'
const editCard = {
    namespaced: true,
    state: {
        canvas: null
    },
    getters: {
        canvas: (state) => {
            return state.canvas
        }
    },
    mutations: {
        SET_CANVAS(state, data) {
            state.canvas = data
            controls(state.canvas)
        }
    }
}
export default editCard