<template>
    <div>
        <router-view/>
    </div>
    <Footer></Footer>
    <VipPopup></VipPopup>
</template>
<script setup name="applayout">
    import VipPopup from '@/components/vipPopup'
    import { onMounted } from 'vue'
    import Footer from './footer.vue'
    import {user} from '@/api/https'

    import { useStore } from 'vuex'
    const store = useStore()

    import changeRouter from '@/hooks/routerChange';
    const { getQuery } = changeRouter()
    let { uid } = getQuery()

    import { useRoute } from "vue-router";
    const route = useRoute()
    onMounted(() => {
        if(route.fullPath != '/monitor/home' && !uid){
            user.get_info().then((res) => {
                store.dispatch('common/setUserInfo', res.data)
            })
        }
    })
</script>