<template>
    <van-popup v-model:show="show" teleport="body" class="vipPopup">
        <div class="imgWrap" :class="{vip: userInfo.vip_id == 2 && !userInfo.is_vip}">
            <!-- <div v-if="userInfo.vip_id == 0 && !userInfo.is_vip && !userInfo.is_trial">
                <div class="text">免费试用</div>
                <div>关注公众号免费3天试用</div>
                <div>3天试用期内可任意体验会员功能</div>
            </div> -->
            <div v-if="userInfo.vip_id == 2 && !userInfo.is_vip">
                <div class="text">会员已到期</div>
                <div>您的会员权益已到期</div>
                <div>续费会员可继续使用全部功能</div>
            </div>
            <div v-if="userInfo.vip_id == 1 && !userInfo.is_trial">
                <div class="text">3天试用已结束</div>
                <div>3天试用期已结束</div>
                <div>开通会员可使用全部功能</div>
            </div>
            <div v-if="userInfo.vip_id == 1 && userInfo.is_trial">
                <div class="time">
                    剩余
                    <van-count-down :time="calcTime()">
                        <template #default="timeData">
                            <span class="block">{{ timeData.days }}</span>
                            <span class="colon">天</span>
                            <span class="block">{{ timeData.hours }}</span>
                            <span class="colon">时</span>
                            <span class="block">{{ timeData.minutes }}</span>
                            <span class="colon">分</span>
                            <span class="block">{{ timeData.seconds }}</span>
                            <span class="colon">秒</span>
                        </template>
                    </van-count-down>
                
                </div>
                <div>3天试用期内可任意体验会员功能</div>
                <div>试用结束后会员功能将关闭</div>
            </div>
            <div class="btn" @click="routeTo">{{btnText()}}</div>
            <!-- <van-button type="primary" @click="routeTo" ></van-button> -->
        </div>
        <div class="closeBtn" @click="closePopup()"></div>
    </van-popup>
</template>
<script setup>
    import { onMounted, computed} from 'vue'

    import { useStore } from 'vuex'
    const store = useStore()

    import changeRouter from '@/hooks/routerChange'
    const { changePath } = changeRouter()

    const show = computed(() =>{
        return store.getters['common/showVip']
    })


    const calcTime = () => {
        let timer = 0
        let { vip_expiry } =  userInfo.value
        if(vip_expiry){
            timer = new Date(vip_expiry).getTime() - new Date()
        }
        return timer
    }

    const userInfo = computed(() =>{
        return store.getters['common/userInfo']
    })
    
    const btnText = () => {
        let text = '开通会员'
        let {vip_id, is_vip} = userInfo.value
        if(vip_id == 2 && !is_vip){
            text = '续费会员'
        }
        // if(vip_id == 0 && !is_trial){
        //     text="免费试用"
        // }

        return text
    }

    onMounted(() => {
    })

    const closePopup = () => {
        store.commit('common/SET_VIP', false)
        store.commit('common/SET_VIP_STATE', false)
    }

    const routeTo = () => {
        let {vip_id, is_trial} = userInfo.value
        if(vip_id == 0 && !is_trial){
            changePath('/userInfo')
        }else{
            changePath('/vip')
        }

     
        closePopup()
    }

</script>
<style scoped lang="less">
.vipPopup{
    .imgWrap{
        width: 590px;
        height: 690px;
        background: url(../../public/image/vip_popup1.png);
        background-size: 100% 100%;
        margin-bottom: 60px;
        padding-top: 380px;
        text-align: center;
        color: #333333;
        font-size: 26px;
        line-height: 1.8;
        &.vip{
            background: url(../../public/image/vip_popup2.png);
            background-size: 100% 100%; 
        }
        .text{
            color: #FE812E;
            margin-bottom: 30px;
        }
        .number{
            color: #FE812E;
        }
    }
}

.btn{

    width: 370px;
    height: 100px;
    background: url(../../public/image/red_btn.png);
    background-size: 100% 100%; 
    color: #fff;
    line-height: 100px;
    letter-spacing: 2px;
    margin: 20px auto 0;
    font-size: 30px;
}
.time{
    display: flex;
    align-content: center;
    font-size: 14Px;
    line-height: 20Px;
    justify-content: center;
    margin-bottom: 30px;
    .block{
        color: #FE812E;
    }
}
.closeBtn{
    width: 66px;
    height: 66px;
    background: url(../../public/image/closebtn.png);
    background-size: 100% 100%;
    margin: 0 auto;
}

</style>