<template>
    <Header title="我的收藏" :space="false" className="whiteBg">
        <van-list
            v-model:loading="loading"
            :finished="finished"
            :finished-text="list.length ? '没有更多了' : '暂无数据'"
            @load="onLoad"
        >
            <van-grid :column-num="2" :border="false" >
                <van-grid-item v-for="(card, index) in list" :key="index" class="grid-pd">
                    <div class="box" @click="changePath('/savePoster',{modelId: card.target.id})">
                        <div class="imgCover">
                            <van-image :src="card.target.cover" alt="" />
                        </div>
                        <div class="content">
                            {{ card.target.title }}
                        </div>
                    </div>
                </van-grid-item>
            </van-grid>
        </van-list>
    </Header>
</template>
<script setup name="collects">
    import { ref, onMounted } from 'vue';
    import { user } from '@/api/https'

    import Header from '@/components/header.vue'

    import changeRouter from '@/hooks/routerChange'
    const { changePath } = changeRouter()

    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const page = ref(1)

    const getList = () => {
        
        user.list_collect({
            type: 'poster_template',
            page: page.value,
            pageSize: 10
        }).then(({data}) => {
            let result = data.data.filter(el => el.target)
            list.value.push(...result)
            if(data.has_more){
                page.value += 1
                getList()
            }
            finished.value = !data.has_more
        })
    }
    onMounted(()=>{
        getList()
    })
    const onLoad = () => {
        getList()
    }
</script>
<style scoped lang="less">
.box{
    box-shadow: 0 0 10px rgba(0,0,0,.15);
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    .imgCover{
        width: 100%;
        img{
            width: 100%;
        }
    }
    .content{
        padding: 20px;
    }
}
</style>
