<template>
    <Header title="奖励规则">
        <div class="box">
            <div class="title redText">推荐奖励规则说明</div>
            <div class="sTitle strong">一、推荐奖励的目的</div>
            <div>为触达更多用户，促进平台健康持久的发展，特设立推荐奖励机制。若您认为平台提供的功能非常实用，能够在工作生活中帮助您降本增效。可以将本平台推荐给您的好友。平台将从会员费用中抽出一定比例作为奖励，用来感谢您为平台的推广所付出的时间和精力。</div>
            <div class="sTitle strong">二、奖励的计算规则</div>
            <div class="redText">新会员奖励33%：</div>
            <div>每推荐1位好友付费成为新会员，您可获得会员金额33%的现金奖励。</div>
            <!-- <div>例如，经过您对本平台的介绍，您的好友愿意付费成为会员。</div>
            <div>若付了<span class="strong">1年会员￥380</span>，您就可以获得380元*33%=<span class="strong">125.4元</span>；</div>
            <div>若付了<span class="strong">2年会员￥580</span>，您就可以获得580元*33%=<span class="strong">191.4元</span>。</div> -->
            <div class="redText">老会员奖励15%：</div>
            <div>每推荐1位老会员好友续费时，您可以获得会员金额15%的现金奖励。</div>
            <div  class="sTitle strong">三、如何获取奖励？</div>
            <div>仅需完成2个步骤：</div>
            <div class="strong">步骤1：生成带有自己名片信息的海报，保存到手机相册；</div>
            <div class="strong">步骤2：将海报发送给好友，引导好友识别海报上的二维码；</div>
            <div>当好友在识别您的二维码后再去付费，您就能获得奖励了。</div>
            <div  class="sTitle strong">四、在哪生成海报？</div>
            <div>可通过以下3种方式：</div>
            <div>（1）在【我的】>【推荐使用】中的海报；</div>
            <div>（2）在【首页】>【海报助手】中生成的海报；</div>
            <div>（3）在【首页】>【海报示例】中生成的海报；</div>
            <div class="sTitle strong">五、注意事项</div>
            <div class="redText">好友在付费时，您必须是会员身份且未到期，才能获得奖励哦~</div>
            <div class="redText">好友在付费前，最后识别的必须是您的海报，才能获得奖励哦~</div>
        </div>
    </Header>
</template>
<script setup name="rule">

import { onMounted } from 'vue';
import { useStore } from 'vuex';
const store = useStore()

onMounted(() => {
    store.commit('common/SET_LOADING', false)
})
</script>
<style scoped lang="less">
.box{
    margin: 30px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    >div{
        padding: 10px 0; 
        line-height: 1.8;
    }

}
.title{
    font-size: 35px;
    text-align: center;
}
.sTitle{
    font-size: 30px;
}
.strong{
    font-weight: 600;
}
.redText{
    color: #e21616;
}
</style>