<template>
    <Header title="个人头像" :space="false">
        <div class="headImg" >
            <div class="imgWrap">
                <van-image :src="imgUrl"></van-image>
            </div>
            <div class="btns">
                <!-- <van-button type="primary" block > -->
                    <van-uploader class="uploader" :before-read="beforeRead" style="width: 100%">
                        <van-button type="primary" block>更换头像</van-button>
                    </van-uploader>
                <!-- </van-button> -->
            </div>
        </div>
    </Header>
    <van-popup v-model:show="show" position="bottom" :style="{ height: '100%', background: '#000' }" class="popup">
        <vue-cropper
            ref="myCropper" 
            class="myCropper"
            :img="option.img" 
            :output-size="option.size" 
            :output-type="option.outputType"
            :info="false" 
            :full="option.full" 
            :can-move="option.canMove" 
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox" 
            :original="option.original" 
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth" 
            :auto-crop-height="option.autoCropHeight" 
            :center-box="option.centerBox"
            :high="option.high"
        ></vue-cropper>
        <div class="cropperBottom">
            <div class="line"></div>
            <div class="btns">
                <span @click="show=false">取消</span>
                <span @click="save">保存</span>
            </div>
        </div>
    </van-popup>
</template>
<script setup name="headerImg">
import { onMounted, reactive, ref, toRefs } from "vue";
import { user, common } from '@/api/https'
import { BaseToFile } from '@/utils/fileChange'

const myCropper = ref()
onMounted(() => {
    getInfo()
})

const imgUrl = ref(null)
const getInfo = () => {
    user.get_info().then(({data}) => {
        imgUrl.value = data.avatar
        state.option.img = data.avatar
    })
}

const beforeRead = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    common.upload(formData).then(({data}) => {
        state.option.img = data.fullurl
        state.show = true
    })
}

const state = reactive({
    show: false,
    option:{
        img: '',
        size: 1,
        full: false,
        outputType: 'png',
        canMove: true,
        fixedBox: true,
        original: false,
        canMoveBox: false,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: document.documentElement.clientWidth*0.9,
        autoCropHeight: document.documentElement.clientWidth*0.9,
        centerBox: true,
        high: true,
    },
})

const save = () =>{
    myCropper.value.getCropData((data) => {
        const file = BaseToFile(data)
        let formData = new FormData()
        formData.append('file', file)
        common.upload(formData).then(({data}) => {
            user.update_info({avatar: data.fullurl}).then(() => {
                state.show = false
                getInfo()
            })
        })
    })
}
const {
    show,
    option,
} = toRefs(state)
</script>
<style scoped lang="less">
.headImg{
    position: fixed;
    width: 100%;
    top:46Px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    .imgWrap{
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        >.van-image{
            width: 100vw;
            height: 100vw;
        }
    }
    .btns{
        padding: 30px;
        display: flex;
        gap: 30px
    }
}
.cropperBottom{
    padding: 0 20px;
    position: fixed;
    width: 100%;
    bottom: 0;
    .line{
        height: 1Px;
        background: #ccc;
    }
    .btns{
        padding: 50px 30px;
        display: flex;
        justify-content: space-between;
        font-size: 30px;
        color: #fff;
        letter-spacing: 2px
    }
}
</style>