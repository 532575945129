<template>
    <div>
    </div>
</template>
<script setup name="login">
import { wxLogin, toIndex } from '@/api/https'
import { onMounted } from 'vue';
import { useStore } from 'vuex';
const store = useStore()
onMounted(() => {
    store.commit('common/SET_LOADING', false)
    const token = getParameterByName('token')
    const openid = getParameterByName('openid')
    window.localStorage.setItem('UserInfo', JSON.stringify({token, openid}))
    if(!token && !JSON.parse(window.localStorage.getItem('UserInfo')).token){
        wxLogin()
    }else{
        toIndex()
    }
})

const getParameterByName = (name) => {
    let url = window.location.href
    // name = name.replace(/[\[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
}
</script> 