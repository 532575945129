<template>
    <Loading>
        <div class="infoDetail" :class="{'active': userInfo.is_vip || userInfo.is_trial}">
            <div class="topHeader">
                <span class="icon"></span>
                <span>爱上智连</span>
                <span class="icon"></span>
            </div>
            <div class="infoBox">
                <div class="info">
                    <div class="headImg"><img :src="userInfo.avatar" alt=""></div>
                    <div class="infoItem">
                        <div>
                            <span class="name">{{userInfo.nickname}}</span>
                            <span class="vip" v-if="userInfo.is_vip"></span>
                        </div>
                        <div class="tel" v-if="userInfo.mobile">{{userInfo.mobile}}</div>
                        <div class="tel ws" v-else @click="changePath('/userInfo')">完善手机号</div>
                    </div>
                </div>
                <div class="setting" @click="changePath('/userInfo')"></div>
            </div>
            <van-row class="nums fs24">
                <van-col span="8">
                    <div class="fs35">{{total.posters}}</div>
                    <div>海报</div>
                </van-col>
                <van-col span="8"> 
                    <div class="fs35">{{total.friends}}</div>
                    <div>好友</div>
                </van-col>
                <van-col span="8"> 
                    <div class="fs35">{{total.award}}</div>
                    <div>累计奖励</div>
                </van-col>
            </van-row>
            <div class="notice fs24" v-if="userInfo.is_vip || userInfo.is_trial">
                <div class="icon-zs"></div>
                <div>到期时间：{{userInfo.vip_expiry}}</div>
            </div>
            <div v-else  class="noticeSpace"></div>
        </div>
        <div class="btns">
               <!-- <van-button>续费会员</van-button> -->
               <van-button block color="#474959" to="/vip"><span class="browColor">{{userInfo.is_vip || userInfo.is_trial ? '续费会员' : '开通会员'}}</span></van-button>
               <van-button type="primary" block text="推荐使用" to="/recommend"/>
        </div>
        <div class="space"></div>
        <van-cell-group>
            <van-cell v-for="(cell, index) in userSetting" :key="index" :title="cell.title" is-link :to="cell.path">
                <template #icon>
                    <van-image class="icons" :src="require(`../../../public/image/user/${cell.icon}`)" alt="" />
                </template>
            </van-cell>
            <van-cell :title="'添加模板'" is-link @click="changePath('/modelList')" v-if="userInfo.is_staff">
                <template #icon>
                    <van-image class="icons" :src="require(`../../../public/image/user/03.png`)" alt="" />
                </template>
            </van-cell>
        </van-cell-group>
        <div class="space"></div>
        <van-cell-group>
            <van-cell v-for="(cell, index) in recommends" :key="index" :title="cell.title" is-link @click="changePath(cell.path)">
                <template #icon>
                    <van-image class="icons" :src="require(`../../../public/image/user/${cell.icon}`)" alt="" />
                </template>
            </van-cell>
            <van-cell :title="'联系客服'" is-link @click="contactLink">
                <template #icon>
                    <van-image class="icons" :src="require(`../../../public/image/user/07.png`)" alt="" />
                </template>
            </van-cell>
        </van-cell-group>
        <SubscribePopup ref="popup"></SubscribePopup>
    </Loading>
</template>
<script name="userIndex" setup>
    import { user } from '@/api/https'
    import changeRouter from '@/hooks/routerChange'
    import { onMounted, ref } from 'vue'
    const { changePath } = changeRouter()

    import {useStore} from 'vuex'
    const store = useStore()

    import SubscribePopup from '@/components/subscribe.vue';
    const popup = ref()

    const userSetting = [
        {title: '我的收藏', icon: '01.png', path: '/collects'},
        {title: '我的海报', icon: '02.png', path: '/myPlacard'},
        {title: '我的好友', icon: '03.png', path: '/friends'},
        // {title: '添加模板', icon: '03.png', path: '/modelList'},
    ] 
    const recommends = [
        {title: '推荐使用', icon: '04.png', path: '/recommend'},
        {title: '推荐奖励', icon: '05.png', path: '/reward'},
        {title: '个人信息', icon: '06.png', path: '/userInfo'},
    ]
    onMounted(() => {
        getUserInfo()
        getTotal()
    })

    const total = ref({
        award: 0,
        friends: 0,
        posters: 0
    })
    const getTotal = () => {
        user.get_total().then(({data}) => {
            total.value = data
        })
    }

    const userInfo = ref({
        is_vip: 0
    })
    const getUserInfo = () => {
        user.get_info().then(res => {
            if(!res.data.subscribe){
                popup.value.open()
            }

            store.dispatch('common/setUserInfo', res.data)

            userInfo.value = res.data
        })
    }
    const contactLink = () =>{
        popup.value.open(1)
    }
</script>
<style lang="less" scoped>
.infoDetail {
    background: radial-gradient(circle, #5E89F7 0%, #4571E3 100%);
    padding-top: 30px;
    position: relative;
    color: #fff;
    &.active{
        background: radial-gradient(circle, #292D46 0%, #292D46 100%);
        
    }
    &::before{
        content: '';
        width: 300px;
        height: 200px;
        background: url(../../../public/image/user/huawen.png) no-repeat;
        position: absolute;
        top: 0;
        right: 34px;
        background-position: bottom;
        background-size: 100%;
    }
    .infoBox{
        padding:0 30px;
        display: flex;
        align-items: top;
        position: relative;
        .info{
            flex: 1;
            display: flex;
            padding-top: 20px;
            align-items: center;
            .headImg{
                width: 105px;
                height: 105px;
                border-radius: 100%;
                background: #ccc;
                margin-right: 26px;
                overflow: hidden;
                img{
                    width: 100%;
                }
            }
            .infoItem{
                flex: 1;
                .name{
                    font-size: 41px;
                    margin-right: 20px;
                    vertical-align: middle;
                }
                .vip{
                    width: 101px;
                    height: 37px;
                    background: url(../../../public/image/user/vip.png) no-repeat;
                    background-size: cover;
                    display: inline-block;
                    vertical-align: middle;
                }
                .tel{
                    font-family: ArialMT;
                    font-size: 30px;
                    opacity: 0.6;;
                    margin-top: 10px;
                    &.ws{
                        text-decoration: underline;
                    }
                }
            }
        }
        .setting{
            width: 40px;
            height: 35px;
            background: url(../../../public/image/user/09.png);
            background-size: cover;
        }
    }
    .nums{
        margin-top: 50px;
        text-align: center;
        .fs35{
            margin-bottom: 5px;
        }
    }
    .noticeSpace{
        margin-top: 30px;
        height: 30px;
    }
    .notice{
        margin: 30px 22px 0;
        height: 73px;
        background: linear-gradient(-50deg, #FFCD95 0%, #FFE9D0 100%);
        border-radius: 26px 26px 0px 0px;
        position: relative;
        overflow: hidden;
        display: flex;
        padding-left: 22px;
        align-items: center;
        color: #8D5B24;
        letter-spacing: 1px;
        &::before{
            content: '';
            left: 332px;
            position: absolute;
            top: -20px;
            width: 60px;
            height: 60px;
            background: linear-gradient(-45deg, #FFE9D0 0%, rgba(255,211,160,0) 100%);
            border-radius: 50%;
        }
        &::after{
            content: '';
            position: absolute;
            width: 60px;
            height: 60px;
            background: linear-gradient(-45deg, #FFE9D0 0%, rgba(255,211,160,0) 100%);
            border-radius: 50%;
            right: 12px;
            bottom: -12px;
        }
        .icon-zs{
            width: 27px;
            height: 22px;
            background: url(../../../public/image/user/08.png) no-repeat ;
            background-size: cover;
            margin-right: 15px;
        }
    }
}
.btns{
    padding: 30px 42px;
    display: flex;
    gap: 26px;
   .browColor{
    color: #FFE9D0;
   }
}
.icons{
    width: 40px;
    height: 40px;
    margin-right: 25px;
}
</style>        