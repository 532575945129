<template>
    <van-popup v-model:show="state.show" round :style="{ padding: '15px' }" class="popup">
        <div class="popupTitle">{{state.title}}</div>
        <div class="popupBody">
            <van-cell-group>
                <van-field
                    v-model="state.text"
                    rows="5"
                    autosize
                    type="textarea"
                    class="field"
                    placeholder="添加文字"
                />
            </van-cell-group>
        </div>
        <div class="pupBtn">
            <span @click="state.show = false">取消</span>
            <span @click="confirmText">{{state.title ? '保存' : '确定'}}</span>
        </div>
    </van-popup>
</template>
<script setup name="addTextPopop">
import { reactive, defineExpose, defineEmits } from 'vue';

const emits = defineEmits(['confirm'])
const state = reactive({
    show: false,
    text: '',
    title: ''
})
const open = (data, title="") => {
    state.show = true
    state.title = title
    state.text = data ? data : ''
}

const confirmText = () => {
    if(state.text){
        state.show = false
        emits('confirm', state.text)
    }
}

defineExpose({
    open
})
</script>
<style lang="less" scoped>
.popup{
    .popupTitle{
        font-size: 28px;
        text-align: center;
        margin-bottom: 20px;
    }
    .popupBody{
        width: 500px;
        .field{
            background-color: #f6f7f9;
        }
    }
    .pupBtn{
        padding-top: 25px;
        font-size: 28px;
        text-align: right;
        color: #de8950;
        >span:first-child{
            margin-right: 30px;
            color: #a9a7b2;
        }
    }
}
</style>