<template>
    <Loading>
        <div class="userInfo" :class="{'active': state.userInfo.is_vip || state.userInfo.is_trial}">
            <div class="topHeader">
                <span class="icon"></span>
                <span>爱上智连</span>
                <span class="icon"></span>
            </div>
            <div class="infoBox" v-if="!state.uid">
                <div class="top">
                    <div class="info">
                        <div class="headImg"><van-image :src="state.userInfo.avatar" alt="" /></div>
                        <div class="infoItem">
                            <div class="name">{{state.userInfo.nickname}}</div>
                            <div class="tag">{{state.userInfo.career}}</div>
                            <!-- <div class="tel">18801892233</div> -->
                        </div>
                    </div>
                    <div class="setting" @click="changePath('/userInfo')"></div>
                </div>
                <div class="bottom">
                    <div>
                        <div v-if="space.show_mobile">
                            <span class="icon phoneIcon"></span>
                            <span v-if="state.userInfo.mobile">{{state.userInfo.mobile}}</span>
                            <span v-else>完善手机号
                                <!-- 可<span class="test">试用三天</span> -->
                            </span>
                        </div>
                        <div v-if="space.show_wechat">
                            <span class="icon wechart"></span>
                            <span>{{state.userInfo.wechat ? state.userInfo.wechat : '未填写'}}</span>
                        </div>
                    </div>
                    <van-button color="linear-gradient(90deg, #2A2E38 0%, #292D46 100%)" to="/recommend" round size="small"><span class="btnText">推荐使用</span></van-button>
                </div>
            </div>
            <div class="infoBox" v-else>
                <div class="top">
                    <div class="info">
                        <div class="headImg"><van-image :src="recommendInfo.user.avatar" alt="" /></div>
                        <div class="infoItem">
                            <div class="name">{{recommendInfo.user.nickname}}</div>
                            <div class="tag">{{recommendInfo.user.career}}</div>
                        </div>
                    </div>
                </div>
                <div class="bottom">
                    <div>
                        <div v-if="recommendInfo.setting.show_mobile">
                            <span class="icon phoneIcon"></span>
                            <span>{{recommendInfo.user.mobile}}</span>
                        </div>
                        <div v-if="recommendInfo.setting.show_wechat">
                            <span class="icon wechart"></span>
                            <span>{{recommendInfo.user.wechat}}</span>
                        </div>
                    </div>
                    <van-button color="linear-gradient(90deg, #2A2E38 0%, #292D46 100%)" @click="showPopup" round size="small"><span class="btnText">我也要AI助手</span></van-button>
                </div>
            </div>
        </div>
        <div class="pd_30 mt-30">
            <van-cell :border="false" title="AI小助手" title-class="cell_title" is-link @click="routeTo('/monitor/helper')" value="更多"/>
            <van-grid :column-num="3" :border="false">
                <van-grid-item  @click="routeTo('/monitor/placard')">
                    <div class="round mb_20">
                        <img class="img100" :src="require(`../../../public/image/helper_01.png`)" alt="">
                    </div>
                    <span class="gridText">海报助手</span>
                </van-grid-item>
                <van-grid-item v-for="(link, index) in helperList" :key="index" @click="routeTo('/send', {id: link.id})">
                    <div class="round mb_20">
                        <van-image class="img100" :src="link.icon" alt=""/>
                    </div>
                    <span class="gridText">{{ link.name }}</span>
                </van-grid-item>
            </van-grid>
            <van-cell :border="false" title="海报小助手" title-class="cell_title" is-link @click="routeTo('/monitor/placard')" value="更多"/>
            <van-grid :column-num="2" :border="false" >
                <van-grid-item v-for="(card, index) in state.plaCardList" :key="index" class="grid-pd">
                    <div class="box" @click="routeTo('/savePoster', {modelId: card.id})">
                        <div class="imgCover">
                            <van-image :src="card.cover" alt=""/>
                        </div>
                        <div class="content">
                            {{ card.title }}
                        </div>
                    </div>
                </van-grid-item>
            </van-grid>
        </div>
        <SubscribePopup ref="popup"></SubscribePopup>
    </Loading>
</template>
<script setup name="home">
import { reactive, onMounted, ref } from 'vue';
import { home, user, module, aiHelper } from '@/api/https'
import changeRouter from '@/hooks/routerChange';
const { getQuery, changePath } = changeRouter()
import { Dialog } from 'vant';

import {useStore} from 'vuex'
const store = useStore()

import SDK from '@/utils/sdk'

const { loadWechatJSSDK } = SDK()

import SubscribePopup from '@/components/subscribe.vue';
const popup = ref()
/* eslint-disable*/

const state = reactive({
    userInfo: {},
    plaCardList:[],
    uid: ''
})
const space = reactive({
    show_mobile: '',
    show_wechat: ''
})

onMounted(()=>{
    let { uid } = getQuery()
    if(uid){
        home.info().then(res => {
            if(res.data.user.id != Number(uid) ){
                state.uid = Number(uid)
                getInfo(uid)
            }else{
                getUserInfo()
            }
        }).catch(()=>{
            state.uid = Number(uid)
            getInfo(uid)
        })
    }else{
        getUserInfo()
    }
    getPosters()
    getHelper()
})

const getUserInfo = () => {
    let inviteUid = localStorage.getItem('inviteUid')
    user.get_info().then((res) => {
        if(!res.data.subscribe){
            popup.value.open()
        }

        store.dispatch('common/setUserInfo', res.data)

        if(inviteUid && inviteUid != res.data.id){
            user.invite_friend({inviter_uid: inviteUid}).then(() => {
                localStorage.removeItem('inviteUid')
            })
        }
        state.userInfo = res.data
        getInfo(res.data.id)
        loadWechatJSSDK(res.data.id)
    })
}

const helperList = ref()

const getHelper = () => {
    let request = {
        cid: '',
        page: 1,
        pageSize: 8
    }
    aiHelper.list(request).then(res => {
        helperList.value = res.data.data
    })
}
const recommendInfo = ref({
    user:{},
    setting: {}
})
const getInfo = (uid) => {
    home.info({uid}).then(({data})=> {
        recommendInfo.value = data
        let {show_mobile, show_wechat} = data.setting
        space.show_mobile = show_mobile 
        space.show_wechat = show_wechat 
    })
}

const getPosters = () => {
    let request = {
        cid: 1,
        page: 1,
        pageSize: 10
    }
    module.list(request).then(({data}) => {
       state.plaCardList = data.data
    })
}

const routeTo = (path, query={}) =>{
    if(state.uid){
        showPopup()
    }else{
        let {is_vip, is_trial} = state.userInfo
        if(!is_vip && !is_trial){
            store.commit('common/SET_VIP_STATE', true)
            store.dispatch('common/setVipState')
        }else{
            changePath(path, query)
        }
    }
}

const showPopup = () => {
    Dialog.confirm({
        title: '提示',
        message:
            '您也要AI助手吗？',
        })
    .then(() => {
        localStorage.setItem('inviteUid', state.uid)
        changePath('/', {uid: state.uid})
    })
    .catch(() => {
        // on cancel
    });
}

</script>
<style lang="less" scoped>
.round{
    width: 80px;
    height: 80px;
    border-radius: 100%;
}
.gridText{
    font-weight: 600;
    font-size: 26px;
}
.box{
    border: 1px solid #eee;
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    .imgCover{
        width: 100%;
        img{
            width: 100%;
        }
    }
    .content{
        padding: 20px;
    }
}
.mt-30{
    margin-top: -70px;
    box-shadow: 0 0 20px rgba(120, 122, 169, .2);
}
.userInfo {
    position: relative;
    &::before{
        content: '';
        position: absolute;
        height: 460px;
        background: radial-gradient(circle, #5E89F7 0%, #4571E3 100%);
        clip-path: ellipse(80% 80% at 50% 0%);
        width: 750px;
        top: 0;
        left: 0;
    }
    .infoBox{
        margin: 10px 16px 0;
        background: url(../../../public/image/bg.png) no-repeat;
        width: 716px;
        height: 427px;
        background-size: 100%;
        position: relative;
        .top{
            display: flex;
            align-items: top;
            padding: 40px 50px;
            .info{
                flex: 1;
                display: flex;
                padding-top: 20px;
                align-items: center;
                .headImg{
                    width: 110px;
                    height: 110px;
                    border-radius: 100%;
                    background: #ccc;
                    margin-right: 26px;
                    overflow: hidden;
                    img{
                        width: 100%;
                    }
                }
                .infoItem{
                    flex: 1;
                    .name{
                        font-size: 30px;
                        margin-right: 20px;
                        vertical-align: middle;
                    }
                    .tag{
                        background: #EAD4FF;
                        font-size: 20px;
                        color: #AA83CF;
                        padding: 5px 10px;
                        border-radius: 6px;
                        display: inline-block;
                        margin-top: 25px;
                    }
                }
            }
            .setting{
                width: 37px;
                height: 34px;
                background: url(../../../public/image/edit.png);
                background-size: cover;
            }
        }
        .bottom{
            padding: 0 55px 0 45px;
            display: flex;
            justify-content: space-between;
            color: #AAABBA;
            line-height: 1.6;
            font-size: 26px;
            align-items: center;
            .icon{
                display: inline-block;
                vertical-align: middle;
                width: 28px;
                height: 22px;
                margin-right: 8px;
                &.phoneIcon{
                    background: url(../../../public/image/phone.png) no-repeat;
                    background-size: contain;
                }
                &.wechart{
                    background: url(../../../public/image/weChart.png) no-repeat;
                    background-size: contain;
                }
            }
            .test{
                color: #5E89F7;
            }
            .btnText{
                color: #FFCD95;
                font-size: 28px;
                padding: 0 10px;
            }
        }
    }
    &.active::before{
        background: radial-gradient(circle, #3F4351 0%, #242830 100%);
        
    }
    &.active{
        .infoBox{
            background: url(../../../public/image/bg-acitve.png) no-repeat;
            background-size: 100%;
            .top{
                .info{
                    .infoItem{
                        .tag{
                            background: #FCCD97;
                            color: #C98B44;
                        }
                    }
                }

            }

            .bottom{
                color: #8C5B24;
                .icon{
                    &.phoneIcon{
                        background: url(../../../public/image/phone_active.png) no-repeat;
                        background-size: contain;
                    }
        
                    &.wechart{
                        background: url(../../../public/image/weChart_active.png) no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
    }
}
.imgCover{
    width: 100%;
}
</style>