<template>
    <van-overlay :show="loading" class="cover opacity" z-index="100">
        <van-loading color="#5E89F7"></van-loading>
    </van-overlay> 
    <div class="wrap">
        <div class="content">
            <van-nav-bar
                :title="title"
                left-arrow
                :border="false"
                @click-left="goBack"
            />
            <div id="mainBox">
                <canvas id="canvas"></canvas>
            </div>
            <div class="bar">
                <div @click="showAddText(0)">
                    <div class="icon"><van-icon name="font-o" /></div>
                    <div>加字</div>
                </div>
                <div>
                    <van-uploader class="uploader" :before-read="beforeRead">
                        <div class="icon"><van-icon name="photo-o" /></div>
                        <div>加图</div>
                    </van-uploader>
                </div>
                <div @click="showAddText(1)">
                    <div class="icon"><van-icon name="sign" /></div>
                    <div>保存</div>
                </div>
                <!-- <div @click="update">
                    <van-uploader class="uploader" :accept="'json'" :before-read="beforeRead2">
                        <div class="icon"><van-icon name="sign" /></div>
                        <div>上传</div>
                    </van-uploader>
                </div> -->
            </div>
        </div>
    </div>
    <SubscribePopup ref="popup"></SubscribePopup>
    <TextEdit></TextEdit>
    <layerPopup></layerPopup>
    <TransperPopup ref="transper"></TransperPopup>
    <addTextPopup ref="addText" @confirm="confirmText" @close="isSave = false"></addTextPopup>
</template>
<script name="editCard" setup>
import { nextTick, onMounted, onUnmounted, provide, ref } from 'vue'
import { fabric } from 'fabric'
import { Toast } from 'vant';
import { Dialog } from 'vant';
// import { fabric } from '@/hooks/selects'
import { controls } from './controlsPlugin'
import { useStore } from 'vuex';
import TextEdit from './modules/textEdit.vue';
import layerPopup from './modules/layerPopup.vue';
import TransperPopup from './modules/transperPopup.vue'
import addTextPopup from '@/views/placard/modules/addTextPopup.vue';

import changeRouter from '@/hooks/routerChange'
const { repacePath, getQuery, backPath } = changeRouter()
import { module, poster, common, user } from '@/api/https'

// import AlloyFinger from 'alloyfinger';
import { BaseToFile } from '@/utils/fileChange'
/**eslint-disable */
let canvas = null
const store = useStore()
const getLayer = ref()

const title = ref()

const loading = ref(true)

import SubscribePopup from '@/components/subscribe.vue';
const popup = ref()


onMounted(async() => {
    nextTick(async() => {
        await createCanvas()
        getModel()
    })
})

onUnmounted(() => {
    store.commit('common/SET_TEXT_SHOW', false)
    store.commit('common/SET_LAYER_SHOW', false)
    store.commit('common/SET_TRANSPER_SHOW', false)
    canvas.dispose()
    canvas = null
})
const width = document.documentElement.clientWidth
const createCanvas = async() => {
    return new Promise((resolve) => {
        canvas = new fabric.Canvas('canvas', {
            width : 0.9*width,
            height : 0.9*width*12/8,
            backgroundColor: '#fff',
            selection: true,
            evented: true,
            preserveObjectStacking: true,
        })
        controls(canvas)
        canvas.on({
            'mouse:down': (event) => mouseDown(event),
            // 'object:added': (event) => save(event),
            // 'object:modified': (event) => save(event),
            // 'object:update': (event) => save(event),
        })
        // fingerMove()
        resolve()
    })
}
// const fingerMove = () => {
//     new AlloyFinger(document.getElementById('canvas'), {
//         pinch:  (evt) => {
//             alert(evt)
//             // 获取手势缩放比例
//             let zoom = evt.zoom;
//             // 获取当前 canvas 的缩放比例
//             let currentZoom = canvas.getZoom() || 1;
//             // 计算新的缩放比例
//             let newZoom = currentZoom * zoom;
//             // 设置 canvas 的缩放比例
//             if(newZoom > 2 || newZoom<0.5) return
//             canvas.setZoom(newZoom);
//             canvas.renderAll();
//         }
//     });
// }

// const lockLayer = () => {
//     let layers = canvas.getObjects()
//     console.log(layers)
//     layers.forEach(el => {
//         el.lockMovementX = true
//         el.lockMovementY = true
//         el.lockRotation = true
//     })
// }
// const unlocklayer = () => {
//     let layers = canvas.getObjects()
//     layers.forEach(el => {
//         el.lockMovementX = false
//         el.lockMovementY = false
//         el.lockRotation = false
//     })
// }
const getModel = () => {
    let { modelId, posterId } = getQuery()
    if(modelId){
        module.detail({id:modelId}).then(res => {
            getLayer.value = res.data
            title.value = res.data.title
            let getter = JSON.parse(res.data.layer)
            getter.objects.forEach(function (object) {
                changeWidth(object)
                if (object.type === 'image') {
                    object.crossOrigin = 'anonymous';
                }
            });
            canvas.loadFromJSON(getter,function(){
                canvas.renderAll();
                loading.value = false
            }, function(o, object) {
                if (object && object.type === 'image') {
                    object.set({
                        crossOrigin: 'anonymous' // 设置 crossOrigin 属性为匿名
                    });
                }
            });
           
        })
    }
    if(posterId){
        poster.detail({id: posterId}).then(res => {
            getLayer.value = res.data
            title.value = res.data.title
            let getter = JSON.parse(res.data.layer)
            getter.objects.forEach(function (object) {
                changeWidth(object)
                if (object.type === 'image') {
                    object.crossOrigin = 'anonymous';
                }
            });
            canvas.loadFromJSON(getter,function(){
                canvas.renderAll();
                loading.value = false
            }, function(o, object) {
                if (object && object.type === 'image') {
                    object.set({
                    crossOrigin: 'anonymous' // 设置 crossOrigin 属性为匿名
                    });
                }
            });
        })
    }
}
const changeWidth = (group) => {
    let canvasWidth = canvas.width;
    // // 计算图片的缩放比例
    let scaleX = canvasWidth*group.scaleX / getLayer.value.width;
    let scaleY = canvasWidth*group.scaleX / getLayer.value.width;
    let scale = Math.max(scaleX, scaleY);
    // // 缩放组中的所有对象
    group.scaleX = scale;
    group.scaleY = scale;
}
const transper = ref()

const mouseDown = (event) => {
    if(event.target){
        store.commit('common/SET_LAYER_SHOW', false)
        store.commit('common/SET_TRANSPER_SHOW', false)
        if(event.target.type === 'i-text'){
            store.commit('common/SET_TEXT_SHOW', true)
        }
        if(event.target.type === 'image'){
            store.commit('common/SET_TEXT_SHOW', false)
        }
    }else{
        store.commit('common/SET_LAYER_SHOW', false)
        store.commit('common/SET_TRANSPER_SHOW', false)
        store.commit('common/SET_TEXT_SHOW', false)
    }
}

const saveCanvas = async(val) => {
    let json = JSON.stringify(canvas.toJSON(['id', 'gradientAngle', 'selectable', 'hasControls']));
    let { modelId, posterId } = getQuery()
    let newCanvas = await copyCanvas()
    saveImg(newCanvas).then(fullurl =>{
        if(modelId){
            poster.add({
                title: val,
                cid: 1,
                width: 0.9*width,
                cover: fullurl,
                template_id: getLayer.value.id,
                height: 1200,
                layer: json
            }).then((res) => {
                loading.value = false
                Toast.success('添加成功')
                repacePath('/savePoster', {posterId: res.data.id})
            })
        }
        if(posterId){
            poster.edit({
                id: posterId,
                title: val,
                cid: 1,
                width: 0.9*width,
                cover: fullurl,
                template_id: getLayer.value.template_id,
                height: 1200,
                layer: json
            }).then(() => {
                Toast.success('更新成功')
                backPath()
            })
        }
    })
}
const saveImg = (newCanvas) => {
    return new Promise((resolve, reject) => {
        let imgData = newCanvas.toDataURL({
            format: 'png',
            quality: 1
        });
        const file = BaseToFile(imgData)
        let formData = new FormData()
        formData.append('file', file)
        common.upload(formData).then(({data}) => {
            resolve(data.fullurl)
        }).catch(() => {
            reject()
        })
    })
}  

const copyCanvas = () => {
    return new Promise(resolve => {
        let json = JSON.stringify(canvas.toJSON(['id', 'gradientAngle', 'selectable', 'hasControls']));
        let data = JSON.parse(json)
        let newCanvas = new fabric.StaticCanvas(null, { 
            width : 0.9*width,
            height : 0.9*width*12/8,
        });
        setZoom(newCanvas)
        data.objects.forEach(object => {
            if(object.type ==='image'){
                object.crossOrigin = 'anonymous';
            }
        })
        newCanvas.loadFromJSON(data,function(){
            newCanvas.renderAll();
            resolve(newCanvas)
        })
    })
}

const setZoom = (newCanvas) => {
    let devicePixelRatio = window.devicePixelRatio || 1;
    newCanvas.setDimensions({ width: newCanvas.width * devicePixelRatio, height: newCanvas.height * devicePixelRatio });

    // 将画布的 CSS 尺寸设置为实际尺寸
    newCanvas.setWidth(newCanvas.width);
    newCanvas.setHeight(newCanvas.height);

    // 将画布的缩放比例设置为设备像素比
    newCanvas.setZoom(devicePixelRatio);
}

const goBack = () => {
    Dialog.confirm({
        title: '提示',
        message:
            `您还没有保存海报，是否保存？`,
        })
    .then(() => {
        showAddText(1)
    })
    .catch(() => {
        backPath()
    });
}

// const downFile = (fileStr, fileType) =>  {
//   const anchorEl = document.createElement('a');
//   anchorEl.href = fileStr;
//   anchorEl.download = `${uuid()}.${fileType}`;
//   document.body.appendChild(anchorEl); // required for firefox
//   anchorEl.click();
//   anchorEl.remove();
// }


// const getJson = () => {
//     let json = localStorage.getItem('canvasJson');
//     canvas.loadFromJSON(json, () => {
//         console.log(JSON.parse(json))
//         canvas.renderAll();
//     });
// }

const beforeRead = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    loading.value = true
    common.upload(formData).then(({data}) => {
        addImage(data.fullurl)
    })
    // let reader = new FileReader();
    // reader.onload = (event) => {
    //     let imgObj = new Image();
    //     imgObj.src = event.target.result;
    //     imgObj.onload = () => {
    //         const imgWidth = imgObj.width
    //         const documentWidth = document.documentElement.clientWidth*0.9
    //         const left = document.documentElement.clientWidth*0.05
    //         let fabricImg = new fabric.Image(imgObj, {
    //             left: left,
    //             top: left,
    //             scaleX: documentWidth/imgWidth,
    //             scaleY: documentWidth/imgWidth,
    //             crossOrigin: 'anonymous'
    //         });
    //         canvas.add(fabricImg);
    //         canvas.renderAll();
    //     };
    // };
    // reader.readAsDataURL(file);
}

const addImage = (url) => {
    new fabric.Image.fromURL(url, (oImg) => {
        const imgWidth = oImg.width
        const documentWidth = document.documentElement.clientWidth*0.9
        oImg.set({
            left: 10,
            top: 10,
            scaleX: documentWidth/imgWidth,
            scaleY: documentWidth/imgWidth,
            myType: 'image',
            crossOrigin: 'Anonymous'
        });
        canvas.add(oImg);
        loading.value = false
  });
}

const addText = ref()
const isSave = ref(false)
const showAddText = (type) => {
    isSave.value = type? true :false
    if(type){
        addText.value.open(getLayer.value.title, '修改标题')
    }else{
        addText.value.open()
    }
}

const confirmText = (val) => {
    if(isSave.value){
        loading.value = true
        user.get_info().then((res) => {
            if(!res.data.subscribe){
                popup.value.open().then(() =>{
                    saveCanvas(val)
                })
            }else{
                saveCanvas(val)
            }
        })
    }else{
        store.commit('common/SET_TEXT_SHOW', true)
        let text = new fabric.IText(val, {
            left: 100,
            top: 100,
            fontFamily: 'Arial',
            fill: '#333',
            fontSize: 20,
            editable: false,
            lineHeight: 1.5,
            selectable: true,
            // splitByGrapheme: true
        });
        canvas.add(text);
        canvas.setActiveObject(text);
        canvas.renderAll();
    }
    // text.on('selected', function() {
    //     store.commit('common/SET_TEXT_SHOW', true)
    //     console.log('Text selected');
    // });
    // // 监听文字对象的失去选中事件
    // text.on('deselected', function() {
    //     store.commit('common/SET_TEXT_SHOW', false)
    // });
}
// const beforeRead2 = (file) => {
//     let reader = new FileReader();
//     reader.readAsText(file, 'UTF-8');
//     reader.onload = () => {
//         canvas.loadFromJSON(reader.result, () => {
//             console.log(JSON.parse(reader.result))
//             canvas.renderAll();
//         });
//     };
// }


provide('canvasEditor', () => canvas)
provide('fabric', fabric)

</script>
<style lang="less" scoped>
.wrap{
    height: 100vh;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    .content{
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .topHeader{
        color: #333;
    }
    #mainBox{
        flex: 1;
        background: rgba(238, 238, 238, .7);
        padding: 40px 0;
        display: flex;
        justify-content: center;
        overflow: auto;
    }
}
.bar {
    display: block;
    height: 50Px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    div {
        flex: 1;
        text-align: center;
        position: relative;
        // font-size: 25px;
        .icon{
            font-size: 45px;
        }
    }
}
</style>