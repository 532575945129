<template>
    <Header title="推荐使用" :space="false">
        <div class="recommend" ref="wrap">
            <!-- <div class="text">长按保存图片</div> -->
            <canvas id="myCanvas" v-show="false"></canvas>
            <van-image :src="composeImg" class="img" alt="" lazy-load>
                <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                </template>
            </van-image>
        </div>
    </Header>
</template>
<script setup name="recommend" >
/*eslint-disable */
    import { nextTick, onMounted, onUnmounted, ref } from 'vue';
    import { user, apiHttp } from '@/api/https'
    import { fabric } from 'fabric'

    const wrap = ref()
    let canvas = null
    const composeImg = ref()

    // const bgWidth = 378
    // const bgHeight = 737
    let offsetWidth = 0
    let offsetHeight = 0

    onMounted(() => {
        nextTick(() => {
            user.get_info().then(({data}) => {
                offsetWidth = wrap.value.offsetWidth
                offsetHeight = wrap.value.offsetHeight
                user.qrcode({uid: data.id}).then(res => {
                    init(data, res.data.image)
                })
            })
        })
    })
    onUnmounted(() => {
        canvas.dispose()
        canvas = null
    })

    const init = async({avatar, nickname}, code) => {
        canvas = new fabric.Canvas('myCanvas', {
            width: offsetWidth,
            // height: offsetWidth*bgHeight/bgWidth,
            height: offsetHeight,
            backgroundColor: '#000b34',
            selection: false,
            evented: false,
        })
        await addBg()
        await addHeadImg(avatar)
        await addCode(code) 
        await addText('我是', {left: 90, top: 28})
        await addText(nickname, {left: 90, top: 55})
        await addText('诚邀您体验使用爱上智连人工智能工具', {left: 20, top: 90, fontSize: 18, fontStyle: 'italic'})
        await addText('长按识别二维码注册属于您的人工智能平台', {left:  offsetWidth-300, top:  offsetHeight - 40, fontSize: 15})
        // await addText('', {left: offsetWidth-300, top: offsetHeight - 80, fontSize: 15})
        canvasToImage()
    }

    const addBg = () => {
        return new Promise((resolve) => {
            let url = `${apiHttp}/uploads/20240103/d0bee43d6e6e31e2b3eb4305f39081fc.png`
            fabric.Image.fromURL(url, function (img) {
                img.set({
                    top: 0,
                    left: 0,
                    scaleX: offsetWidth/img.width,
                    selectable: false,
                    crossOrigin: 'anonymous',
                })
                canvas.add(img).renderAll();
                
                resolve()
            });
        })
    }

    const addHeadImg = (avatar) => {
        return new Promise((resolve) => {
            fabric.Image.fromURL(avatar, function(img){
                const imgWidth = img.width;
                const imgHeight = img.height;
    
                let radius = 4*imgWidth/55
                let clipPath = new fabric.Rect({
                    width: imgWidth,
                    height: imgHeight,
                    rx: radius, // 圆角的横向半径
                    ry: radius, // 圆角的纵向半径
                    originX: 'center',
                    originY: 'center'
                });
                img.set({
                    top: 20,
                    left: 20,
                    selectable: false,
                    scaleX: 55/imgWidth,
                    scaleY: 55/imgHeight,
                    crossOrigin: 'anonymous',
                })
                img.clipPath = clipPath;
                canvas.add(img).renderAll();
                resolve()
            })
        })
    }
    const addCode = (avatar) => {
        return new Promise((resolve) => {
            fabric.Image.fromURL(avatar, function(img){
                const imgWidth = img.width;
                const imgHeight = img.height;
                const showWidth = 120

                let radius = 4*imgWidth/showWidth
                let clipPath = new fabric.Rect({
                    width: imgWidth,
                    height: imgHeight,
                    rx: radius, // 圆角的横向半径
                    ry: radius, // 圆角的纵向半径
                    originX: 'center',
                    originY: 'center'
                });
                img.set({
                    top: offsetHeight - 180,
                    left: offsetWidth - showWidth - 30,
                    selectable: false,
                    scaleX: showWidth/imgWidth,
                    scaleY: showWidth/imgHeight,
                    crossOrigin: 'anonymous',
                })
                img.clipPath = clipPath;
                canvas.add(img).renderAll();
                resolve()
            });
        })
    }

    const addText = (val, {left, top, fontSize=16, fontStyle='normal'}) =>{
        return new Promise((resolve) => {
            let text = new fabric.IText(val, {
                left,
                top,
                fontFamily: 'Arial',
                fill: '#fff',
                fontSize,
                editable: false,
                selectable: true,
                fontStyle,
            });
            canvas.add(text);
            canvas.renderAll();
            resolve()
        })
    }


    const canvasToImage = async() => {
        let newCanvas = await copyCanvas()
        let imgData = newCanvas.toDataURL({
            format: 'png',
            quality:1
        });
        composeImg.value = imgData
    }

    const copyCanvas = () => {
        return new Promise(resolve => {
            let json = JSON.stringify(canvas.toJSON(['id', 'gradientAngle', 'selectable', 'hasControls']));
            let data = JSON.parse(json)
            let newCanvas = new fabric.StaticCanvas(null, { 
                width: offsetWidth,
                height: offsetHeight,
            });
            setZoom(newCanvas)
            data.objects.forEach(object => {
                if(object.type ==='image'){
                    object.crossOrigin = 'anonymous';
                }
            })
            newCanvas.loadFromJSON(data,function(){
                newCanvas.renderAll();
                resolve(newCanvas)
            })
        })
    }
    const setZoom = (newCanvas) => {
    let devicePixelRatio = window.devicePixelRatio || 1;
    newCanvas.setDimensions({ width: newCanvas.width * devicePixelRatio, height: newCanvas.height * devicePixelRatio });

    // 将画布的 CSS 尺寸设置为实际尺寸
    newCanvas.setWidth(newCanvas.width);
    newCanvas.setHeight(newCanvas.height);

    // 将画布的缩放比例设置为设备像素比
    newCanvas.setZoom(devicePixelRatio);
}

</script>
<style scoped lang="less">
.recommend{
    position: fixed;
    width: 100%;
    top: 46Px;
    bottom: 0;
    // background: url(../../../public/image/recommend.png);
    // background-size: cover;
    // #myCanvas{
    //     width: 100%;
    //     height: 100%;
    // }
    .img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .text{
        position: absolute;
        bottom: 10Px;
        z-index: 10;
        width: 100%;
        color: #fff;
        font-size: 28px;
        text-align: center;
    }
}
</style>