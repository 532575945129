<template>
      <van-popup
        v-model:show="show"
        position="bottom"
        :overlay-style="{background: 'none'}"
        class="van-safe-area-bottom"
    >
        <div class="wrap">
            <div class="option">
                <span @click="show = false">取消</span>
                <span class="save">完成</span>
            </div>
            <div class="box">
                <div v-for="(colors, index) in colorList" :key="index" class="squareBox">
                    <div>
                        <div class="square" v-for="(color, sIndex) in colors" :key="sIndex" @click="chooseColor(color)" :style="{background: color}"></div>
                    </div>
                </div>
            </div>
        </div>
    </van-popup>
</template>
<script setup name="colorPick">
    import {ref, defineExpose, defineEmits } from 'vue'

    const emit = defineEmits(['change'])

    const colorList = [
        ['#ffffff', '#e4e4e4', '#b2b2b2','#989898', '#7f7f7f', '#4c4c4c','#262626', '#000000'],
        ['#fdcbd4', '#e9999a', '#e07376','#e95555', '#f04941', '#e04241','#d03539', '#c42f31'],
        ['#f2bbd0', '#ee90b2', '#ec6492','#e8477d', '#e82c67', '#d52963','#c2245f', '#ae2058'],
        ['#ddbfe5', '#cb92d6', '#b767c6','#aa48b9', '#9b2db0', '#8d2aa9','#7b21a4', '#67209a'],
        ['#cec2e8', '#b49cd8', '#9275c9','#7c57c1', '#643cb6', '#5b38b0','#512fa7', '#47289f'],
        ['#c4c9e7', '#a0a8d9', '#7c85c8','#636bc0', '#4351b4', '#3d49ab','#34419f', '#2c3592'],
        ['#bfdff8', '#98cbf6', '#74b6f3','#5da6f2', '#4498f2', '#4388e3','#3676ce', '#2f65bf'],
        ['#bbe6f9', '#8fd3f8', '#69c3f7','#52b6f4', '#46abf1', '#419be5','#378ace', '#3277ba'],
        ['#baebf0', '#91ddea', '#6cd4e1','#58c7d8', '#4fbed2', '#48afc2','#3f96a7', '#36848e'],
        ['#b5dfdb', '#8dcac3', '#63b4ab','#4ba79c', '#3b9589', '#348b7b','#31796d', '#266a5b'],
        ['#cbe5c8', '#a9d4a6', '#8ac686','#73ba6e', '#60b057', '#55a04e','#498d42', '#407d38'],
        ['#dbecca', '#c8e1a8', '#b1d387','#a2c970', '#90c158', '#81b04e','#6f9d45', '#5b8a3c'],
        ['#eef0c8', '#e4eaa2', '#d9e581','#d2df6d', '#cbda59', '#bdc84e','#acb244', '#9b9b39'],
        ['#faf8c7', '#faf5a4', '#faef86','#faeb72', '#f9e960', '#f6d758','#f3bd50', '#f1a746'],
        ['#f9ecb8', '#f8df8b', '#f8d466','#f6c84e', '#f7c147', '#f7b141','#f6a03d', '#f78d35'],
        ['#f9e1b5', '#f7ca87', '#f3b75e','#f5a745', '#f89739', '#f38a37','#f38a37', '#e96d2f'],
        ['#fcccc2', '#f7ab94', '#f6896c','#f6896c', '#fa593a', '#ee5438','#e14d31', '#d3452d'],
    ]
    const show = ref(false)

    const open = () => {
        show.value = true
    }
    const chooseColor = (val) => {
        emit('change', val)
        show.value = false
    }
    defineExpose({ open });
</script>
<style lang="less" scoped>
.wrap{
    padding: 20px 30px 30px;
    .option{
        display: flex;
        justify-content: space-between;
        font-size: 26px;
        padding-bottom: 20px;
        .save{
            color: orangered;
            letter-spacing: 2px;
        }
    }
    .box{
        height: 350px;
        overflow: auto;
        .squareBox{
            display: flex;
            margin: 15px 0;
            justify-content: center;
            >div{
                overflow: hidden;
                border-radius: 6px;
                border: 1px solid #eee;
                display: flex;
                .square{
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
}
</style>