<template>
    <van-overlay :show="loading" class="cover" z-index="100">
        <van-loading color="#5E89F7"></van-loading>
    </van-overlay> 
    <div class="vip" v-show="!loading">
        <div class="topHeader">
            <span class="icon" @click="back">
                <van-icon name="arrow-left" />
            </span>
            <span>开通会员</span>
            <span class="icon"></span>
        </div>
        <div class="nameBox">
            <div class="headImg">
                <van-image :src="userInfo.avatar" alt=""/>
            </div>
            <div>
                <div class="name fs36">{{userInfo.nickname}}</div>
                <div class="text fs26">{{userInfo.is_vip ? `会员到期：${userInfo.vip_expiry}` : '暂未开通'}}</div>
            </div>
        </div>
        <img class="pic" src="../../../public/image/user/text.png" alt="">
        <div class="introduce">
            <div v-for="(pic, index) in Imgs" :key="index"><van-image :src="require(`../../../public/image/user/${pic}`)" alt=""/></div>
        </div>
        <div class="roundBox">
            <van-notice-bar left-icon="volume-o" color="#666666" background="transparent" :scrollable="false">
                <van-swipe
                    vertical
                    class="notice-swipe"
                    :autoplay="3000"
                    :touchable="false"
                    :show-indicators="false"
                >
                    <van-swipe-item v-for="(item, index) in users" :key="index">恭喜**<span class="nameText">{{item.nickname}}</span>*******</van-swipe-item>
                </van-swipe>
            </van-notice-bar>
            <div class="fs32 choose">选择会员套餐</div>
            <div class="vipList">
                <div class="radioBox" :class="{'active': state.current == index}" v-for="(item, index) in list" :key="index" @click="change(index)">
                    <div class="fs30 text">超值{{ item.month }}月</div>
                    <div class="num"><span class="fs30">￥</span>{{ item.price }}</div>
                    <div class="normalPrice fs24">原价￥{{ item.market_price }}</div>
                    <div class="line"></div>
                    <div class="fs24">每日仅需{{calcPrice(index) }}元</div>
                </div>
            </div>
            <van-button color="linear-gradient(-90deg, #FFE9D0, #FFCD95)" block class="lj-btn" @click="order"><span>立即开通</span></van-button>
        </div>
    </div>
</template>
<script name="vip" setup>
/*eslint-disable */
    import { onMounted, reactive, toRefs } from "vue"
    import { user } from '@/api/https'
    import { Toast } from "vant"

    import {useStore} from 'vuex'
    const store = useStore()

    onMounted(() =>{
        getUserInfo()
        history_user()
        get_price()
       
    })

    const state = reactive({
        current: 0,
        userInfo: {},
        loading: true,
        list: [],
        users: []
    })

    const getUserInfo = () =>{
        user.get_info().then(({data}) =>{
            console.log(data)
            store.commit('common/SET_VIP_STATE', false)
            store.dispatch('common/setUserInfo', data)
            state.loading = false
            state.userInfo = data
        })
    }

    const get_price = () => {
        user.vip_price().then(({data}) => {
            state.list = data
        })
    }

    const history_user = () => {
        user.vip_users().then(({data}) => {
            state.users = data
        })
    }

    const calcPrice = (index) =>{
        let { price, month } = state.list[index]
        return (price / (month * 30)).toFixed(2)
    }

    const Imgs = [
        'vip1.png',
        'vip2.png',
        'vip3.png',
        'vip4.png',
        'vip5.png',
        'vip6.png'
    ]

    const change = (index) => {
        state.current = index
    }   
    const back = () =>{
        history.back()
    }

    const order = () => {
        let { month } = state.list[state.current]
        user.vip_order({month}).then(({data}) =>{
            WeixinJSBridge.invoke( 
                'getBrandWCPayRequest', 
                {...data.pay_config},
                (res) => {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        Toast.success('支付成功')
                        getUserInfo()
                    }
                }
            );
        })
    }

    const {
        userInfo,
        loading,
        list,
        users
    } = toRefs(state)
</script>
<style lang="less" scoped>
.vip{
    background: #292D46;
    min-height: 100vh;
    .nameBox{
        margin: 50px 60px 0;
        height: 180px;
        background: url(../../../public/image/user/vipBg.png) no-repeat;
        background-size: cover;
        padding: 30px 40px;
        display: flex;
        align-items: center;
        .headImg{
            width: 104px;
            height: 104px;
            border-radius: 100%;
            background: #eee;
            margin-right: 25px;
            overflow: hidden;
            img{
                width: 100%;
            }
        }
        .name{
            color: #1F2020;
            margin-bottom: 5px;
        }
        .text{
            color: #55300F;
        }
    }
    .pic{
        width: 457px;
        margin: 20px auto;
        display: block;
    }
    .introduce{
        display: flex;
        flex-wrap: wrap;
        padding: 0 45px 27px;
        gap: 15px;
        >div{
            width: 210px;
            img{
                width: 100%;
            }
        }
    }
    .roundBox{
        background: #fff;
        border-radius: 40px 40px 0px 0px;
        padding: 25px 30px;
        .notice{
            color: #666666;
            display: flex;
            align-items: center;
            letter-spacing: 1px;
            .noticeIcon{
                width: 32px;
                height: 29px;
                background: url(../../../public/image/user/notice.png) no-repeat;
                background-size: cover;
                margin-right: 26px;
            }
            .nameText{
                color: #333333;
            }
            
        }
        .choose{
            color: #101123;
            padding: 30px 0;
            font-family: 'Helvetica Neue';
        }
        .vipList{
            display: flex;
            justify-content: space-between;
            gap: 15px;
            .radioBox{
                height: 298px;
                border: 3px solid #DDDDDD;
                border-radius: 20px;
                text-align: center;
                color: #666;
                &.active{
                    background: linear-gradient(0deg, rgba(255,239,217,0.97), rgba(255,255,255,0.97));
                    border: 3px solid #E5B284;
                }
                .text{
                    margin-top: 25px;
                    color: #333;
                }
                .num{
                    color: #E9372C;
                    font-size: 68px;
                    font-weight: 600;
                    margin: 20px 0 20px;           
                }
                .normalPrice{
                    color: #999999;
                    text-decoration: line-through;
                    margin: 0 auto 20px;
                }
                .line{
                    width: 216px;
                    height: 1Px;
                    background: #ccc;
                    margin: 0 auto 15px;
                }
            }
        }
    }
    .lj-btn{
        span{
            color: #8D5B24;
            letter-spacing: 2px;
            font-size: 30px;
        }
        margin: 60px 0 60px;
    }
}
.notice-swipe {
    height: 40px;
    line-height: 40px;
  }
</style>