<template>
    <van-popup
        v-model:show="show"
        position="top"
        :overlay="false"
    >
        <div class="wrap">
            <div class="icons">
                <div v-for="(img, index) in imgs" :key="index" @click="changeLayer(index+1)">
                    <van-image :src="require(`../../../../public/image/${img.img}`)" alt="" :class="img.className" />
                    <div>{{ img.title }}</div>
                </div>
            </div>
        </div>
    </van-popup>
</template>
<script name="layerPopup" setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    const imgs = [
        {
            img: 'up1.png',
            className:'',
            title: '上移一层',
        },
        {
            img: 'up1.png',
            className: 'transfrom',
            title: '下移一层',
        },
        {
            img: 'up2.png',
            className:'',
            title: '置于顶层',
        },
        {
            img: 'up2.png',
            className: 'transfrom',
            title: '置于底层',
        }
    ]

    import useSelect from './useCanvas'
    const { canvasEditor } = useSelect()

    const store = useStore()
    const show = computed(() => {
        return store.getters['common/showLayer']
    })

    const changeLayer = (number) => {
        const activeObject = canvasEditor().getActiveObjects()[0]
        if(number === 1){
            activeObject && activeObject.bringForward()    // 上移一层
        }
        if(number === 2){
            activeObject && activeObject.sendBackwards()  //下移一层
        }
        if(number === 3){
            activeObject && activeObject.bringToFront() 
        }
        if(number === 4){
            activeObject && activeObject.sendToBack() 
        }
        let bgLayer = canvasEditor().getObjects().filter(el => el.type == 'bg')[0]
        if(bgLayer){
            bgLayer.sendToBack() 
        }
        canvasEditor().renderAll();
    }


</script>
<style lang="less" scoped>
.wrap{
    padding:30px;
    .icons{
        display: flex;
        justify-content: space-around;
        text-align: center;
        img{
            width: 35px;
            height: 35px;
            margin-bottom: 5px;
            &.transfrom{
                transform: rotateZ(180deg);
            }
        }
    }
}

</style>