<template>
    <Loading>
        <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          >
          <van-grid :column-num="2" :border="false" >
              <van-grid-item v-for="(card, index) in list" :key="index" class="grid-pd">
                  <div class="box" @click="routerTo(card.id)">
                      <div class="imgCover">
                          <van-image :src="card.cover" alt=""/>
                      </div>
                      <div class="content">
                          {{ card.title }}
                      </div>
                  </div>
              </van-grid-item>
          </van-grid>
      </van-list>
    </Loading>
</template>
<script setup name="placard">
    import { ref, onMounted } from 'vue'
    import { module } from '@/api/https'
    import changeRouter from '@/hooks/routerChange';
    const { changePath } = changeRouter()

    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const page = ref(1)
    const getList = () => {
        let request = {
            cid: 1,
            page: page.value,
            pageSize: 5
        }
        module.list(request).then(({data}) => {
            list.value.push(...data.data)
            if(data.has_more){
                page.value += 1
                getList()
            }
            finished.value = !data.has_more
        })
    }
    onMounted(() =>{
        getList()
    })
    const onLoad = () => {
        getList()
    };
    const routerTo = (id) =>{
        changePath('/savePoster', {modelId: id})
    }
</script>
<style lang="less" scoped>
.box{
    border: 1px solid #eee;
    border-radius: 6px;
    overflow: hidden;
    width: 100%;
    .imgCover{
        width: 100%;
        img{
            width: 100%;
        }
    }
    .content{
        padding: 20px;
    }
}
</style>