<template>
    <van-overlay :show="loading" class="cover">
        <van-loading color="#5E89F7"></van-loading>
    </van-overlay> 
    <div v-show="!loading">
        <slot ></slot>
    </div>
</template>
<script setup name="loading">
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    const loading = computed(() => {
        return useStore().getters['common/loading']
    })
</script>