<template>
    <van-popup 
        v-model:show="show" 
        position="bottom"
        :overlay-style="{background: 'none'}"
        class="van-safe-area-bottom"
    >
        <div class="wrap">
            <div class="popupBody" style="justify-content: space-between;">
                <div class="color" @click="pickShow" :style="{backgroundColor: state.shadow.color}"></div>
                <div class="vt_M">
                    <van-button type="primary" size="small" @click="refresh"><van-icon name="revoke" /></van-button>
                </div>
            </div>
            <div class="popupBody">
                <span>模糊</span>
                <div class="slide">
                    <van-slider v-model="state.shadow.blur" @update:model-value="onChange" />
                </div>
                <span>{{state.shadow.blur}}</span>
            </div>
            <div class="popupBody">
                <span>水平距离</span>
                <div class="slide">
                    <van-slider :min="-100" :max="100"  v-model="state.shadow.offsetX" @update:model-value="onChange" />
                </div>
                <span>{{state.shadow.offsetX}}px</span>
            </div>
            <div class="popupBody">
                <span>垂直距离</span>
                <div class="slide">
                    <van-slider :min="-100" :max="100" v-model="state.shadow.offsetY" @update:model-value="onChange" />
                </div>
                <span>{{state.shadow.offsetY}}px</span>
            </div>
            <div class="pupBtn">
                <span @click="confirmText(0)">取消</span>
                <span @click="confirmText(1)">完成</span>
            </div>
        </div>
        <ColorPick ref="colorPick" @change="change"></ColorPick>
    </van-popup>
</template>
<script setup name="shadow">
import { reactive, defineExpose, ref } from 'vue';
import useSelect from './useCanvas'
import ColorPick from './colorPicker.vue'
const { canvasEditor, fabric } = useSelect()

const show = ref(false)

const state = reactive({
    shadow: {
        color: '#333',
        blur: 0,
        offsetX: 0,
        offsetY: 0,
    },
    default: {}
})
const open = () => {
    const activeObject =  canvasEditor().getActiveObjects()[0];
    let val = activeObject.shadow
    if(val){
        state.shadow = val
        state.default = {...val}
    }
   show.value = true
}

const onChange = () => {
    const activeObject =  canvasEditor().getActiveObjects()[0];
    activeObject && activeObject.set('shadow', new fabric.Shadow(state.shadow));
    canvasEditor().renderAll();
}

const confirmText = (type) => {
    show.value = false
    const activeObject = canvasEditor().getActiveObjects()[0];
    if(!type){
        activeObject && activeObject.set('shadow', new fabric.Shadow(state.default));
        canvasEditor().renderAll();
    }
}

const colorPick = ref()
const pickShow = () =>{
    colorPick.value.open()
}
const change = (val) =>{
    state.shadow.color = val
    onChange()
}

const refresh = () => {
    state.shadow = {
        color: '#333',
        blur: 0,
        offsetX: 0,
        offsetY: 0,
    }
    onChange()
}

defineExpose({
    open
})
</script>
<style lang="less" scoped>
.wrap{
    padding: 40px 30px 30px;
    .popupBody{
        display: flex;
        // justify-content: space-around;
        font-size: 30px;
        align-items: center;
        gap: 40px;
        padding: 20px 0;
        &.disable{
            color: #696974;
        }
        .color{
            width: 100px;
            height: 40px;
            border-radius: 6px;
        }
        .vt_M{
            >*{
                vertical-align: middle;
            }
        }
        >span{
            white-space: nowrap;
        }
        >span:first-child{
            width: 120px;
        }
        >span:last-child{
            width: 50px;
        }
        .slide{
            flex: 1;
        }
    }
    .pupBtn{
        padding-top: 20px;
        font-size: 28px;
        display: flex;
        justify-content: space-between;
            color: #de8950;
            >span:first-child{
                margin-right: 30px;
                color: #1F2136;
            }
    }
}
</style>