<template>
    <van-overlay :show="loading" class="cover" z-index="100">
        <van-loading color="#5E89F7"></van-loading>
    </van-overlay> 
    <div class="bg" :class="className" v-show="!loading">
        <van-nav-bar
            :title="title"
            left-arrow
            fixed
            :border="false"
            @click-left="routerTo"
            :style="{background: bg}"
        />
        <div class="mt_46" >
            <van-sticky :offset-top="46" v-if="space">
                <div class="space"></div>
            </van-sticky>
            <slot></slot>
        </div>
    </div>
</template>
<script setup name="Header">
import { defineProps, computed} from 'vue';

import changeRouter from '@/hooks/routerChange';
const { backPath, changePath } = changeRouter()

import { useStore } from 'vuex';
const store = useStore()

const loading = computed(() => {
    return store.getters['common/loading']
})

const props = defineProps({
    title:{
        type: String,
        default: ''
    },
    space: {
        type: Boolean,
        default: true
    },
    url:{
        type: String,
        default: ''
    },
    bg:{
        type: String,
        default: '#fff'
    },
    className:{
        type: String,
        default: ''
    }
})
const routerTo = () => {
    if(props.url){
        changePath(props.url)
    }else{
        backPath()
    }
}

</script>
