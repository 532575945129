<template>
    <Header title="我的海报" :space="false" className="whiteBg" url="/monitor/user">
        <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
        <van-grid :column-num="2" :border="false" >
            <!-- <van-grid-item class="grid-pd">
                <van-button @click="changePath('/createModel',{type: 1})" >
                    <van-icon name="plus" />
                </van-button>
            </van-grid-item> -->
            <van-grid-item v-for="(card, index) in list" :key="index" class="grid-pd">
                <div class="box">
                    <div class="imgCover" @click="routerTo(card.id)">
                        <van-image :src="card.cover" alt="" />
                    </div>
                    <div class="content">
                        {{ card.title }}
                    </div>
                    <div class="btns">
                        <van-icon name="edit" @click="routerTo(card.id)"/>
                        <van-icon name="delete" @click="remove(card)"/>
                    </div>
                </div>
            </van-grid-item>
        </van-grid>
    </van-list>
    </Header>
</template>
  <script name="myPlacard" setup>
    import Header from '@/components/header.vue'
    import { ref, onMounted } from 'vue'
    import { Dialog } from 'vant';
    import { poster } from '@/api/https'
    import changeRouter from '@/hooks/routerChange';
    const { changePath } = changeRouter()

    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const page = ref(1)
    const getList = () => {
        let request = {
            cid: 1,
            page: page.value,
            pageSize: 5
        }
        poster.list(request).then(({data}) => {
            list.value.push(...data.data)
            if(data.has_more){
                page.value += 1
                getList()
            }
            finished.value = !data.has_more
        })
    }
    onMounted(() => {
        getList()
    })
    const onLoad = () => {
        getList()
    };
    const routerTo = (id) =>{
        changePath('/savePoster', {posterId: id})
    }
    const remove = ({id, title}) => {
        Dialog.confirm({
            title: '提示',
            message:
                `确定删除${title}海报`,
            })
        .then(() => {
            poster.delete({id}).then(() => {
                list.value = list.value.filter(el => el.id != id)
            })
        })
        .catch(() => {
        });
    }
  </script>
  <style lang="less" scoped>
  .box{
      border: 1px solid #eee;
      border-radius: 6px;
      overflow: hidden;
      width: 100%;
      height: 100%;
      .imgCover{
          width: 100%;
          img{
              width: 100%;
          }
      }
      .content{
          padding: 20px;
      }
  }
  .btns{
    font-size: 40px;
    padding: 10px 0;
    display: flex;
    justify-content: space-around;
    border-top: 1px solid #eee;
    color: #de8950;
  }
  </style>