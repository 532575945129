<template>
    <Header title="我的好友">
        <van-list
            v-model:loading="state.loading"
            :finished="state.finished"
            :finished-text="state.list.length ? '没有更多了' : '暂无数据'"
            @load="onLoad"
        >
            <van-cell v-for="list in state.list" :key="list.friend_uid" :value="list.create_time" class="lable_no_top">
                <template #title>
                    <div class="dsp">
                        <div class="round"><van-image :src="list.friend.avatar" alt=""/></div>
                        <div>
                            <div class="name">{{list.friend.nickname}}</div>
                            <van-tag color="#5E89F7">{{list.friend.career}}</van-tag>
                        </div>
                    </div>
                </template>
            </van-cell>
        </van-list>
    </Header>
</template>
<script setup name="friends">
    import { onMounted, reactive } from 'vue';
    import { user } from '@/api/https'

    import Header from '@/components/header.vue'

    const state = reactive({
        loading: false,
        finished: true,
        list:[]
    })

    onMounted(() => {
        getList()
    })

    const getList = () => {
        user.firend_list().then(({data}) => {
            state.list = data.data
        })
    }
    
    const onLoad = () => {
        getList()
    }

</script>
<style scoped lang="less">
.round{
    width: 80px;
    height: 80px;
    background-color: #eee;
    border-radius: 100%;
    margin-right: 30px;
    overflow: hidden;
    img{
        width: 100%;
    }
}
.name{
    font-size: 30px;
    color: #24242C;
    font-weight: 600;
    line-height: 1;
}
</style>
