
import { common } from '@/api/https'
/*eslint-disable*/
export default function() {

    const loadWechatJSSDK = (id) => {
        // 引入微信 JSSDK 的逻辑，可以是异步加载或者直接引入
        // 例如可以通过动态创建 script 标签来引入微信 JSSDK
        const script = document.createElement('script');
        script.src = 'https://res.wx.qq.com/open/js/jweixin-1.6.0.js';
        script.onload = () => {
          // 微信 JSSDK 加载完成后的回调
          common.jssdkConfig({url:location.href.split('#')[0]}).then(({data}) => {
              wx.config({
                ...data
              });
              shareToFriendsAndQQ(id)
          })
        };
        document.head.appendChild(script);
    }

    const shareToFriendsAndQQ = (id) => {
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          const link = location.href.split('?')[0] + '?uid=' +id
          common.contact().then(({data}) => {
            let {desc, title, imgUrl} = data.share_info
            
            wx.updateAppMessageShareData({ 
              title, // 分享标题
              desc, // 分享描述
              link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl, // 分享图标
              success: function (res) {
                console.log(res +'分享朋友圈')
                // 设置成功
              }
            })
            
            wx.updateTimelineShareData({ 
              title, // 分享标题
              desc, // 分享描述
              link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl, // 分享图标
              success: function (res) {
                console.log(res +'分享line')
                // 设置成功
              }
            })
          })
        });
    }
    return {
      loadWechatJSSDK
    }
}