import { 
    Tabbar, TabbarItem,
    Cell, CellGroup,
    Col, Row,
    Grid, GridItem,
    NavBar,
    Icon,
    List,
    Tag,
    Sticky,
    Button,
    Uploader,
    Popup,
    Field,
    Checkbox,
    Slider,
    Picker,
    Switch,
    Loading,
    Overlay,
    Empty,
    Swipe, SwipeItem,
    Stepper,
    Form,
    NoticeBar,
    Image as VanImage,
    CountDown,
    Lazyload 
} from 'vant'

export function vant(app){
    app.use(Tabbar)
    app.use(TabbarItem)
    app.use(Cell)
    app.use(CellGroup)
    app.use(Col)
    app.use(Row)
    app.use(Grid)
    app.use(GridItem)
    app.use(NavBar)
    app.use(Icon)
    app.use(List)
    app.use(Tag)
    app.use(Sticky)
    app.use(Button)
    app.use(Uploader)
    app.use(Popup)
    app.use(Field)
    app.use(Checkbox)
    app.use(Slider)
    app.use(Picker)
    app.use(Switch)
    app.use(Loading)
    app.use(Overlay)
    app.use(Empty)
    app.use(Swipe)
    app.use(SwipeItem)
    app.use(Stepper)
    app.use(Form)
    app.use(NoticeBar)
    app.use(VanImage)
    app.use(CountDown)
    app.use(Lazyload)
}