<template>
    <Header :space="false" :title="result.title">
        <div class="space"></div>
        <div class="canvasBox" :style="{minHeight: `${0.9*width*12/8}px`}">
            <!-- <canvas id="myCanvas"></canvas> -->
            <van-image :src="composeImg"  class="img" alt="" lazy-load>
                <template v-slot:loading>
                    <van-loading type="spinner" size="20" />
                </template>
            </van-image>
        </div>
        <div class="text">长按图片保存至相册</div>
        <div class="btns">
            <van-button v-if="is_Model" :type="result.is_favorite ? 'primary' : 'default'" @click="changeCollect"><van-icon name="star-o" class="starIcon" /></van-button>
            <van-button type="primary" block @click="routeTo">编辑</van-button>
            <!-- <van-button type="primary" block @click="saveImg">保存到相册</van-button> -->
        </div>
    </Header>
</template>
<script setup name="savePoster">
    import { onMounted, onUnmounted, ref } from 'vue'
    import { fabric } from 'fabric'
    import { module, user, poster } from '@/api/https'
    import changeRouter from '@/hooks/routerChange'
    const { getQuery, repacePath } = changeRouter()
    const result = ref({
        title: '',
        cover: '',
        is_favorite: 0,
    })

    const composeImg = ref()

    const is_Model = ref(false)
    onMounted(() => {
        let { modelId, posterId } = getQuery()
        is_Model.value = modelId ? true : false
        if(is_Model.value){
            module.detail({id: modelId}).then(({data}) => {
                result.value = data
                composeImg.value = data.cover
            })
        }else{
            init(posterId)
        }
    })

    const routeTo = () => {
        let { modelId, posterId } = getQuery()
        repacePath('/editCard', is_Model.value ? {modelId} : {posterId})
    }
    const changeCollect = () => {
        if(result.value.is_favorite){
            user.delete_collect({id:result.value.favorite_id}).then(() => {
                result.value.is_favorite = 0
            })
        }else{
            user.add_collect({
                type: 'poster_template',
                id: result.value.id
            }).then(() => {
                result.value.is_favorite = 1
            })
        }
    }

    let canvas = null

    onUnmounted(() => {
        if(canvas){
            canvas.dispose()
            canvas = null
        }
    })
    const width = document.documentElement.clientWidth
      /*eslint-disable*/
    const init = async(posterId) => {
        canvas = new fabric.Canvas('myCanvas', {
            width : 0.9*width,
            height : 0.9*width*14/8,
            backgroundColor: '#ffffff',
            selection: false,
            evented: false,
        })
   
        await addPoster(posterId)
        let { data } = await user.get_info()
        await addHeadImg(data)
        await addCode(data.id)
        await addText(data.nickname, {left: 95, top: (0.9*width*12/8) + 25})
        await addText(data.mobile, {left: 95, top: (0.9*width*12/8) + 50})
        // setZoom()
        canvasToImage()
        /*eslint-disable*/
    }

    const addPoster = (posterId) => {
        return new Promise((resolve) => {
            poster.detail({id: posterId}).then(({data}) => {
                result.value = data
                fabric.Image.fromURL(data.cover, function(img) {
                    img.set({
                        top: 0,
                        left: 0,
                        scaleX: 0.9*width/img.width,
                        scaleY: (0.9*width*12/8)/img.height,
                        selectable: false,
                        crossOrigin: 'anonymous',
                    })
                    canvas.add(img).renderAll();
                    resolve()
                });
            })
        })
    }

    const addHeadImg = (data) => {
        return new Promise((resolve) => {
            fabric.Image.fromURL(data.avatar, function(img){
                const imgWidth = img.width;
                const imgHeight = img.height;
    
                let clipPath = new fabric.Rect({
                    width: imgWidth,
                    height: imgHeight,
                    rx: imgWidth, // 圆角的横向半径
                    ry: imgWidth, // 圆角的纵向半径
                    originX: 'center',
                    originY: 'center'
                });
                img.set({
                    top: (0.9*width*12/8) + 15,
                    left: 25,
                    selectable: false,
                    scaleX: 60/imgWidth,
                    scaleY: 60/imgHeight,
                    crossOrigin: 'anonymous',
                })
                img.clipPath = clipPath;
                canvas.add(img).renderAll();
                resolve()
            })
        })
    }

    const addCode = (id) => {
        return new Promise((resolve) => {
            user.qrcode({uid: id}).then(({data}) => {
                fabric.Image.fromURL(data.image, function(img){
                    const imgWidth = img.width;
                    const imgHeight = img.height;
                    const showWidth = 60
    
                    let radius = 4*imgWidth/showWidth
                    let clipPath = new fabric.Rect({
                        width: imgWidth,
                        height: imgHeight,
                        rx: radius, // 圆角的横向半径
                        ry: radius, // 圆角的纵向半径
                        originX: 'center',
                        originY: 'center'
                    });
                    img.set({
                        top: (0.9*width*12/8) + 15,
                        left: 0.9*width- 20 - showWidth,
                        selectable: false,
                        scaleX: showWidth/imgWidth,
                        scaleY: showWidth/imgHeight,
                        crossOrigin: 'anonymous',
                    })
                    img.clipPath = clipPath;
                    canvas.add(img).renderAll();
                    resolve()
                });
            })
        })
    }

    const addText = (val, {left, top, fontSize=16, fontStyle='normal'}) =>{
        return new Promise((resolve) => {
            let text = new fabric.IText(val, {
                left,
                top,
                fontFamily: 'Arial',
                fill: '#000',
                fontSize,
                editable: false,
                selectable: true,
                fontStyle,
            });
            canvas.add(text);
            canvas.renderAll();
            resolve()
        })
    }
    const canvasToImage = async() => {
        let newCanvas = await copyCanvas()
        let imgData = newCanvas.toDataURL({
            format: 'png',
            quality: 1
        });
        composeImg.value = imgData
    }

    const copyCanvas = () => {
        return new Promise(resolve => {
            let json = JSON.stringify(canvas.toJSON(['id', 'gradientAngle', 'selectable', 'hasControls']));
            let data = JSON.parse(json)
            let newCanvas = new fabric.StaticCanvas(null, { 
                width : 0.9*width,
                height : 0.9*width*14/8,
            });
            setZoom(newCanvas)
            data.objects.forEach(object => {
                if(object.type ==='image'){
                    object.crossOrigin = 'anonymous';
                }
            })
            newCanvas.loadFromJSON(data,function(){
                newCanvas.renderAll();
                resolve(newCanvas)
            })
        })
    }

    const setZoom = (newCanvas) => {
        let devicePixelRatio = window.devicePixelRatio || 1;
        newCanvas.setDimensions({ width: newCanvas.width * devicePixelRatio, height: newCanvas.height * devicePixelRatio });

        // 将画布的 CSS 尺寸设置为实际尺寸
        newCanvas.setWidth(newCanvas.width);
        newCanvas.setHeight(newCanvas.height);

        // 将画布的缩放比例设置为设备像素比
        newCanvas.setZoom(devicePixelRatio);
    }


</script>
<style lang="less" scoped>
.edit{
    padding: 20px;
    text-align: center;
    font-size: 28px;
    letter-spacing: 2px;
    .icon{
        margin-right: 10px;
        font-size: 34px;
    }
}
.canvasBox{
    width: 90%;
    margin-left: 5%;
    // background: #eee;
    .img{
        width: 100%;
    }
}
.text{
    margin-top: 20px;
    text-align: center;
    color: #919191;
}
.btns{
    padding: 30px;
    display: flex;
    gap: 30px
}
.img{
    width: 100%;
    height: 100%;
}
</style>