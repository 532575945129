<template>
    <van-popup v-model:show="show" teleport="body" class="subscribePopup">
        <div class="imgWrap" v-if="!type">
            <div class="code">
                <img :src="codeImg.mp_qrcode" alt="" class="img">
            </div>
            <div class="text1">长按识别关注公众号,免费试用3天</div>
            <div class="text2" @click="closePopup(1)">已关注公众号</div>
        </div>
        <div class="imgWrap noBg" v-else>
            <div class="code">
                <img :src="codeImg.sc_wechat_qrcode" alt="" class="img">
            </div>
            <div class="text1">长按二维码添加客服微信</div>
        </div>
        <div class="closeBtn" @click="closePopup(0)"></div>
    </van-popup>
</template>
<script setup>
    import {ref, defineExpose, onMounted} from 'vue'
    import { common } from '@/api/https'
    
    const show = ref(false)
    const codeImg = ref({
        mp_qrcode: '',
        sc_wechat_qrcode: ''
    })
    const type = ref(0)
    const callback = ref()

    const open = (val = 0) =>{
        type.value = val
        show.value = true
        return new Promise((resolve) => {
            callback.value = resolve
        })
    }

    onMounted(() => {
        common.contact().then(({data}) => {
            codeImg.value = data
        })
    })

    const closePopup = (type) => {
        show.value = false
        callback.value?.(false)
        if(type){
            common.checkSubscribe()
        }
    }

    defineExpose({
        open
    })
</script>
<style scoped lang="less">
.imgWrap{
    width: 560px;
    height: 657px;
    background: url(../../public/image/subscribe.png);
    background-size: 100% 100%;
    margin-bottom: 60px;
    padding-top: 245px;
    .code{
        width: 260px;
        height: 260px;
        background: #F5F5F5;
        border: 3px solid rgba(37,118,175,0.18);
        border-radius: 10px;
        margin: 0 auto 0;
        .img{
            width: 100%;
            height: 100%;
        }

    }
    &.noBg{
        background: #fff;
        border-radius: 10px;
        height: 560px;
        padding-top: 100px;
        .code{
            margin-bottom: 50px;
        }
    }
}

.closeBtn{
    width: 66px;
    height: 66px;
    background: url(../../public/image/closebtn.png);
    background-size: 100% 100%;
    margin: 0 auto;
}
.text1{
    text-align: center;
    font-size: 26px;
    color: #1168D5;
    margin: 20px 0;
}
.text2{
    font-size: 28px;
    color: #666;
    text-decoration: underline;
    text-align: center;
}
</style>