<template>
    <Header title="提现记录">
        <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            v-if="list.length"
        >
            <van-cell v-for="item in list" :key="item.id" :label="item.apply_time" center class="lable_no_top">
                <template #title>
                    <div class="dsp">
                        <span class="mr_10">提现</span>
                    </div>
                </template>
                <template #value>
                    <div class="rightText">-{{item.cash}}</div>
                    <van-tag plain :color="item.status === 'wait' ? '#11BC65' : '#F49518'">{{item.status_txt}}</van-tag>
                </template>
            </van-cell>
        </van-list>
        <div class="taC" v-if="!list.length">
            <img class="img" src="../../../../public/image/empty.png" alt="">
            <p class="fs30 color_69 mb_20">您还没有提现记录</p>
            <p class="fs24 color_8C">推荐好友使用，并成功开通会员将显示在此处</p>
            <div>
                <van-button class="w320 mb" type="primary" to="/recommend">推荐使用</van-button>
            </div>
            <div>
                <van-button class="w320" plain type="primary" @click="changePath('/rule')">奖励规则</van-button>
            </div>
        </div>

    </Header>
</template>
<script setup name="record" >
    import { onMounted, reactive, toRefs } from 'vue';
    import { reward } from '@/api/https'

    import changeRouter from '@/hooks/routerChange'
    const { changePath } = changeRouter()

    const state = reactive({
        loading: false,
        finished: true,
        list:[]
    })

    onMounted(() => {
        getList(1)
    })

    const getList = (page) => {
        reward.draw_list({page}).then(({data}) => {
            let {has_more, current_page} = data
            if(has_more){
                getList(current_page+1)
            }
            state.list.push(...data.data)
        })
    }


    const {
        list,
        loading,
        finished
    } = toRefs(state)

</script>
<style scoped>
.mr_10{
    color: #24242C;
    font-size: 28px;
}
.rightText{
    font-weight: 600;
    color: #0EBA63;
    font-size: 32px;
}

.img{
    width: 282px;
    height: 327px;
    margin: 110px auto 36px;
}
.mb{
    margin-bottom: 32px;
    margin-top: 60px;
}
</style>
