import { createStore } from "vuex";

import common from "./modules/common";
import editCard from "./modules/editCard";

const store = createStore({
    modules:{
      common,
      editCard
    }
})
    
export default store