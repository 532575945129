<template>
    <Loading>
        <div class="box">
            <van-cell :border="false" title="推荐" title-class="cell_title"/>
            <van-grid :column-num="3" :border="false" :gutter="10">
                <van-grid-item v-for="(link, index) in list" :key="index" @click="routeTo(link)" class="boxShadow">
                    <div class="round mb_20">
                        <van-image class="img100" :src="link.icon" alt=""/>
                    </div>
                    <span class="gridText">{{ link.name }}</span>
                </van-grid-item>
            </van-grid>
        </div>
    </Loading>
</template>
<script name="helper" setup>
import { reactive, toRefs, onMounted } from "vue";
import { aiHelper } from '@/api/https'

import changeRouter from '@/hooks/routerChange';
const { changePath } = changeRouter()

const state = reactive({
    list: []
})

onMounted(() => {
    getList(1)
})

const getList = (page) => {
    let request = {
        cid: '',
        page,
        pageSize: 10
    }
    aiHelper.list(request).then(res => {
        let {has_more, current_page, data} = res.data
        if(has_more){
            getList(current_page+1)
        }
        state.list.push(...data)
    })
}
const routeTo = (val) => {
    changePath('/send', {id: val.id})
}

const { list } = toRefs(state)

</script>
<style lang="less" scoped>
.box{
    padding-bottom: 30px;
}
.round{
    width: 80px;
    height: 80px;
    border-radius: 100%;
}
.gridText{
    font-weight: 600;
    font-size: 26px;
}
</style>