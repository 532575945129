<template>
    <Header title="申请提现">
        <div class="tab">
            <div class="text">请确定收款信息</div>
            <div class="record" @click="changePath('/drawHistory')">提现记录</div>
        </div>
        <van-form @submit="onSubmit">
            <van-cell-group>
                <van-field
                    v-model="account.bank_name"
                    name="银行名称"
                    label="银行名称"
                    placeholder="银行名称"
                    :rules="[{ required: true, message: '请填写银行名称' }]"
                />
                <van-field
                    v-model="account.bank_card"
                    name="银行卡号"
                    label="银行卡号"
                    placeholder="您的卡号"
                    :rules="[{ required: true, message: '请填写银行卡号' }]"
                />
                <van-field
                    v-model="account.holder_name"
                    name="持卡人"
                    label="持卡人"
                    placeholder="持卡人姓名"
                    :rules="[{ required: true, message: '请填写持卡人姓名' }]"
                />
                <van-field
                    v-model="account.id_card"
                    name="身份证号"
                    label="身份证号"
                    placeholder="持卡人身份证号"
                    :rules="[{ required: true, message: '请填写持卡人身份证号' }]"
                />
                <van-field
                    v-model="account.mobile"
                    name="联系电话"
                    label="联系电话"
                    placeholder="持卡人预留电话"
                    :rules="[{ required: true, message: '请填写持卡人预留电话' }]"
                />
                <div class="space"></div>
                <van-cell title="可提现金额" :value="withdraw.cash" />
                <van-cell title="实际到账"  :value="withdraw.cash_after"  />
            </van-cell-group>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit" :disabled="!withdraw.cash">确认提现</van-button>
            </div>
        </van-form>
    </Header>
</template>
<script setup name="cash">
import { onMounted, reactive, toRefs } from "vue";
import { Toast } from 'vant';
import { reward } from '@/api/https'

import { useStore } from 'vuex';
const store = useStore()

import changeRouter from '@/hooks/routerChange'
const { changePath } = changeRouter()

onMounted(() => {
    getInfo()
})

const getInfo = () =>{
    reward.withdraw().then(res =>{
        store.commit('common/SET_LOADING', false)
        let { withdraw, account } = res.data
        state.account = account
        state.withdraw = withdraw
    })
}

const state = reactive({
    account :{
        bank_name: '',
        bank_card: '',
        holder_name: '',
        id_card: '',
        mobile: ''
    },
    withdraw:{
        cash: 0, //可提现税前
        cash_after: 0,//可提现税后
        error: '' //错误信息
    }
})

const onSubmit = () => {
    let { account, withdraw } = state
    if(!withdraw.cash){
        Toast.fail(withdraw.error)
        return
    }
    reward.post_draw({account})
}


const {
    account,
    withdraw
} = toRefs(state)
</script>
<style scoped lang="less">
.tab{
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 30px 30px;
    margin-bottom: 15px;
    .text{
        font-size: 30px;
        font-weight: 600;
    }
    .record{
        color: #969696;
        font-size: 26px;
    }
}

</style>