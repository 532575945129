import axios from "axios";
import { Toast } from 'vant';

// let baseURL = 'https://be.aszhilian.cn'
let baseURL = 'https://zl-cp.dpyouhui.com'
import router from '@/router'
import store from '@/store'
const service = axios.create({
  baseURL,
})
service.interceptors.request.use(config => {
	let userInfo = JSON.parse(window.localStorage.getItem('UserInfo'))
	if (userInfo && userInfo.token != null) {
		config.headers['token'] = userInfo.token
	}
	return config;
}, error => {
	return Promise.reject(error)
});


//response拦截器
service.interceptors.response.use(
	response => {
		store.commit('common/SET_LOADING', false)
		if(response.status==200){
			if(response.data.code){
				return Promise.resolve(response.data);
			}else {
				if(response.config.url !== '/api/space/info'){
					Toast.fail(response.data.msg)
				}
				return Promise.reject(response.data);
			}
		}else{
			Toast.fail(response.data.msg)
			return Promise.reject(response.data);
		}
	},
	error => {
		store.commit('common/SET_LOADING', false)
		if(error.response.status === 401){
			router.push('/')
		}else{
			return Promise.reject();
		}
	}
)

export default service;
