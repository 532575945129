import { createRouter, createWebHashHistory } from "vue-router";
/*eslint-disable*/
import Login from '@/views/login.vue'
import ApplayOut from '@/components/applayout.vue'
import store from '@/store/index'

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/monitor',
        component: ApplayOut,
        redirect: '/monitor/home',
        children: [
           { 
                path: 'home',
                name: 'home',
                // meta: {
                //     parent: 'home',
                //     requireAuth: true
                // },
                component: require('@/views/home/index').default
            },
            { 
                path: 'helper',
                name: 'helper',
                // meta: {
                //     requireAuth: true
                // },
                component: require('@/views/helper/index').default
            },
            { 
                path: 'placard',
                name: 'placard',
                // meta: {
                //     requireAuth: true
                // },
                component: require('@/views/placard/index').default
            },
            { 
                path: 'user',
                name: 'user',
                // meta: {
                //     parent: 'home',
                //     requireAuth: true
                // },
                component: require('@/views/user/index').default
            },
        ]
    },
    {
        path: '/userInfo',
        name: 'userInfo',
        component: require('@/views/user/userInfo').default
    },
    {
        path: '/friends',
        name: 'friends',
        component: require('@/views/user/friends').default
    },
    {
        path: '/editCard',
        name: 'editCard',
        meta: {
            requireAuth: true
        },
        component: require('@/views/placard/editCard').default
    },
    {
        path: '/createModel',
        name: 'createModel',
        component: require('@/views/placard/createModel').default
    },
    {
        path: '/vip',
        name: 'vip',
        component: require('@/views/user/vip').default
    },
    {
        path: '/savePoster',
        name: 'savePoster',
           meta: {
            requireAuth: true
        },
        component: require('@/views/placard/savePoster').default
    },
    {
        path: '/collects',
        name: 'collects',
        component: require('@/views/user/collects').default
    },
    {
        path: '/modelList',
        name: 'modelList',
        component: require('@/views/modelList').default
    },
    {
        path: '/editModel',
        name: 'editModel',
        component: require('@/views/placard/editModel').default
    },
    {
        path: '/myPlacard',
        name: 'myPlacard',
        component: require('@/views/user/myPlacard').default
    },
    {
        path: '/send',
        name: 'send',
        meta: {
            requireAuth: true
        },
        component: require('@/views/helper/send').default
    },
    {
        path: '/reward',
        name: 'reward',
        component: require('@/views/user/reward/index').default
    },
    {
        path: '/rule',
        name: 'rule',
        component: require('@/views/user/reward/rule').default
    },
    {
        path: '/cash',
        name: 'cash',
        component: require('@/views/user/reward/cash').default
    },
    {
        path: '/drawHistory',
        name: 'drawHistory',
        component: require('@/views/user/reward/history').default
    },
    {
        path: '/recommend',
        name: 'recommend',
        component: require('@/views/user/recommend').default
    },
    {
        path: '/headerImg',
        name: 'headerImg',
        component: require('@/views/user/headerImg').default
    },
    
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    let { requireAuth } = to.meta
    let has_uid = !!(to.path === '/monitor/home' && to.query.uid)
    if(to.path != '/'){
        if(window.localStorage.getItem('UserInfo') && JSON.parse(window.localStorage.getItem('UserInfo')).token){
            let {is_vip, is_trial} = store.getters['common/userInfo']
            if(requireAuth){
                if(!is_vip && !is_trial){
                    next(from.path);
                }else{
                    store.commit('common/SET_LOADING', true)
                    window.scrollTo(0, 1);
                    next();
                }
            }else{
                store.commit('common/SET_LOADING', true)
                window.scrollTo(0, 1);
                next();
            }
        }else{
            if(has_uid){
                next()
            }else{
                next({path: '/', replace: true})
            }
        }
    }else{
        next()
    }
});

export default router